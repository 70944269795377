:root { 
  --baby-powder: #fcfcfc;
  --black: #000000;
  --black-pearl: #050e27;
  --cannon-black: #242001;
  --chicago: #5a5a5d;
  --cultured-pearl: #f5f5f5;
  --eucalyptus: #279043;
  --forest-green: #089520;
  --melanzane: #330d2f;
  --melanzane-2: #330d2f80;
  --mountain-mist: #959595;
  --orange-red: #f22020;
  --pumice: #c9c9c9;
  --romance: #fdfdfd;
  --royal-blue: #414bdb;
  --shark: #27292e;
  --sizzling-sunrise: #fbde00;
  --star-dust: #9d9ea4;
  --venus: #998697;
  --white: #ffffff;
  --white-rock: #ebe8d1;
  --white-rock-2: #ece9d2;
 

 
  --font-family-manrope: "Manrope", Helvetica;
  --font-family-nunito_sans: "Nunito Sans", Helvetica;
}

@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');

.nunitosans-normal-melanzane {
  color: var(--melanzane);
  font-family: var(--font-family-nunito_sans);
  /* font-size: 14px; */
  font-style: normal;
  font-weight: 400;
}


.nunitosans-black-venus {
  color: var(--venus);
  font-family: var(--font-family-nunito_sans);
  /* font-size: 20px; */
  font-style: normal;
  font-weight: 900;
  cursor: pointer;
}

.nunitosans-semi-bold-shark {
  color: var(--shark);
  font-family: var(--font-family-nunito_sans);
  /* font-size: 14px; */
  font-style: normal;
  font-weight: 600;
}

.manrope-medium-venus {
  color: var(--venus);
  font-family: var(--font-family-manrope);
  /* font-size: 14px; */
  font-style: normal;
  font-weight: 500;
}

.nunitosans-normal-white {
  color: var(--white);
  font-family: var(--font-family-nunito_sans);
  /* font-size: 9px; */
  font-style: normal;
  font-weight: 400;
}

.nunitosans-normal-forest-green {
  color: var(--forest-green);
  font-family: var(--font-family-nunito_sans);
  /* font-size: 13px; */
  font-style: normal;
  font-weight: 400;
}

.nunitosans-normal-mountain-mist {
  color: var(--mountain-mist);
  font-family: var(--font-family-nunito_sans);
  /* font-size: 14px; */
  font-weight: 400;
}

.nunitosans-normal-mountain-mist-1 {
  color: var(--mountain-mist);
  font-family: var(--font-family-nunito_sans);
  /* font-size: 14px; */
}

.nunitosans-normal-melanzane {
  color: var(--melanzane);
  font-family: var(--font-family-nunito_sans);
  /* font-size: 20px; */
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}

.manrope-normal-forest-green {
  color: var(--forest-green);
  font-family: var(--font-family-manrope);
  /* font-size: 10px; */
  font-style: normal;
  font-weight: 400;
}

.manrope-medium-black-pearl {
  color: var(--black-pearl);
  font-family: var(--font-family-manrope);
  /* font-size: 20px; */
  font-style: normal;
  font-weight: 500;
}

.nunitosans-normal-shark {
  color: var(--shark);
  font-family: var(--font-family-nunito_sans);
  /* font-size: 14px; */
  font-style: normal;
  font-weight: 400;
}

.nunitosans-bold-shark {
  color: var(--shark);
  font-family: var(--font-family-nunito_sans);
  /* font-size: 14px; */
  font-style: normal;
  font-weight: 700;
}

.manrope-normal-shark {
  color: var(--shark);
  font-family: var(--font-family-manrope);
  /* font-size: 16px; */
  font-style: normal;
  font-weight: 400;
}

.manrope-bold-shark {
  color: var(--shark);
  font-family: var(--font-family-manrope);
  /* font-size: 16px; */
  font-style: normal;
  font-weight: 700;
}

.border-1px-melanzane {
  border: 1px solid var(--melanzane);
}

.nunitosans-bold-melanzane {
  color: var(--melanzane);
  font-family: var(--font-family-nunito_sans);
  /* font-size: 11px; */
  font-style: normal;
  font-weight: 700;
}


.nunitosans-bold-white{
  color: var(--white);
  font-family: var(--font-family-nunito_sans);
  /* font-size: 12px; */
  font-style: normal;
  font-weight: 700;
}
.playball-normal-shark{

  color: var(--shark);
  font-family: 'Playball', cursive;
  font-style: normal;
  font-weight: 600;
}
.nunitosans-normal-mist{
  color: var(--mountain-mist);
  font-family: var(--font-family-nunito_sans);
  font-style: normal;
  font-weight: 400;
}

.nunitosans-light-black-pearl{

  color: var(--black-pearl);
  font-family: var(--font-family-nunito_sans);
  font-style: normal;
  font-weight: 300;

}

