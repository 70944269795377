.web-medium-screen {
    align-items: center;
    /* background-color: #fbde00; */
    flex-direction: column;
    height: 2450px;
    overflow: hidden;
    width: 1366px;
  }
  
  .header-main-menu-1 {
    /* align-items: center; */
    /* display: flex; */
    height: 71px;
    margin-top: -1px;
    /* min-width: 1366px; */
    /* padding: 10px 139px; */
  }
  
  :root { 
    --baby-powder: #fcfcfc;
    --black: #000000;
    --black-pearl: #050e27;
    --cannon-black: #242001;
    --chicago: #5a5a5d;
    --cultured-pearl: #f5f5f5;
    --eucalyptus: #279043;
    --forest-green: #089520;
    --haiti: #1d1a33;
    --melanzane: #330d2f;
    --melanzane-2: #330d2f80;
    --mountain-mist: #959595;
    --orange-red: #f22020;
    --pumice: #c9c9c9;
    --romance: #fdfdfd;
    --royal-blue: #414bdb;
    --seashell: #f1f1f1;
    --shark: #27292e;
    --silver-chalice: #acacac;
    --sizzling-sunrise: #fbde00;
    --star-dust: #9d9ea4;
    --turbo: #f9e107;
    --venus: #998697;
    --white: #ffffff;
    --white-rock: #ebe8d1;
    --white-rock-2: #ece9d2;
   
    --font-size-l: 11px;
    --font-size-m: 10px;
    --font-size-xl: 12px;
    --font-size-xxl: 13px;
    --font-size-xxxl: 14px;
    --font-size-xxxxl: 15px;
    --font-size-xxxxxl: 16px;
   
    --font-family-lato: "Lato", Helvetica;
    --font-family-manrope: "Manrope", Helvetica;
    --font-family-nunito_sans: "Nunito Sans", Helvetica;
  }
  
  .manrope-normal-cannon-black-11px {
    color: var(--cannon-black);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-normal-cannon-black-10px {
    color: var(--cannon-black);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-normal-cannon-black-11px-2 {
    color: var(--cannon-black);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-l);
  }
  
  .manrope-normal-cannon-black-10px-2 {
    color: var(--cannon-black);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-m);
  }
  
  .manrope-normal-cannon-black-14px {
    color: var(--cannon-black);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-melanzane-15px {
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-mountain-mist-13px {
    color: var(--mountain-mist);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-semi-bold-black-pearl-12px {
    color: var(--black-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 600;
  }
  
  .nunitosans-semi-bold-shark-14px {
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 600;
  }
  
  .manrope-medium-venus-14px {
    color: var(--venus);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 500;
  }
  
  .lato-medium-white-9px {
    color: var(--white);
    font-family: var(--font-family-lato);
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
  }
  
  .nunitosans-normal-pumice-10px {
    color: var(--pumice);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-medium-black-pearl-18px {
    color: var(--black-pearl);
    font-family: var(--font-family-manrope);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  
  .manrope-bold-forest-green-12px {
    color: var(--forest-green);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 700;
  }
  
  .nunitosans-normal-melanzane-16px {
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-black-venus-18px {
    color: var(--venus);
    font-family: var(--font-family-nunito_sans);
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
  }
  
  .nunitosans-normal-melanzane-17px {
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-normal-cannon-black-14px-2 {
    color: var(--cannon-black);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxl);
  }
  
  .nunitosans-normal-forest-green-13px {
    color: var(--forest-green);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-shark-13px {
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-melanzane-18px {
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-mountain-mist-15px {
    color: var(--mountain-mist);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-melanzane-14px {
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-medium-black-pearl-14px {
    color: var(--black-pearl);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 500;
  }
  
  .nunitosans-normal-mountain-mist-16px {
    color: var(--mountain-mist);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-black-pearl-9px {
    color: var(--black-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-light-black-pearl-12px {
    color: var(--black-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 300;
  }
  
  .nunitosans-normal-black-pearl-16px {
    color: var(--black-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-bold-shark-14px {
    color: var(--shark);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 700;
  }
  
  .manrope-bold-cannon-black-11px {
    color: var(--cannon-black);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
  }
  
  .nunitosans-normal-black-pearl-10px {
    color: var(--black-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-bold-shark-10px {
    color: var(--shark);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
  }
  
  .manrope-bold-cannon-black-10px {
    color: var(--cannon-black);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
  }
  
  .nunitosans-normal-melanzane-12px {
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-normal-white-12px {
    color: var(--white);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-melanzane-13px {
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-melanzane-15px-2 {
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxl);
  }
  
  .nunitosans-black-venus-17px {
    color: var(--venus);
    font-family: var(--font-family-nunito_sans);
    font-size: 17px;
    font-style: normal;
    font-weight: 900;
  }
  
  .nunitosans-normal-orange-red-13px {
    color: var(--orange-red);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-medium-venus-12px {
    color: var(--venus);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 500;
  }
  
  .manrope-normal-forest-green-12px {
    color: var(--forest-green);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-medium-black-pearl-17px {
    color: var(--black-pearl);
    font-family: var(--font-family-manrope);
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
  }
  
  .manrope-bold-shark-18px {
    color: var(--shark);
    font-family: var(--font-family-manrope);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
  
  .nunitosans-normal-black-pearl-12px {
    color: var(--black-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-light-cultured-pearl-11px {
    color: var(--cultured-pearl);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 300;
  }
  
  .nunitosans-light-cultured-pearl-11px {
    color: var(--cultured-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 300;
  }
  
  .nunitosans-light-cultured-pearl-10px {
    color: var(--cultured-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 300;
  }
  
  .nunitosans-black-melanzane-13px {
    color: var(--melanzane-2);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 900;
  }
  
  .manrope-medium-melanzane-11px {
    color: var(--melanzane);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
  }
  
  .manrope-bold-forest-green-10px {
    color: var(--forest-green);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
  }
  
  .nunitosans-normal-forest-green-16px {
    color: var(--forest-green);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-shark-16px {
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-forest-green-15px {
    color: var(--forest-green);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-shark-15px {
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-forest-green-13px-2 {
    color: var(--forest-green);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxl);
  }
  
  .nunitosans-normal-mountain-mist-13px-2 {
    color: var(--mountain-mist);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxl);
  }
  
  .nunitosans-normal-shark-13px-2 {
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxl);
  }
  
  .manrope-light-cultured-pearl-10px {
    color: var(--cultured-pearl);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 300;
  }
  
  .manrope-medium-shark-16px {
    color: var(--shark);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxxxl);
    font-style: normal;
    font-weight: 500;
  }
  
  .nunitosans-semi-bold-melanzane-12px {
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 600;
  }
  
  .nunitosans-black-venus-14px {
    color: var(--venus);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 900;
  }
  
  .nunitosans-normal-orange-red-12px {
    color: var(--orange-red);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-chicago-12px {
    color: var(--chicago);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-medium-venus-11px {
    color: var(--venus);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
  }
  
  .manrope-normal-forest-green-10px {
    color: var(--forest-green);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-normal-orange-red-10px {
    color: var(--orange-red);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-bold-cannon-black-14px {
    color: var(--cannon-black);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 700;
  }
  
  .manrope-bold-shark-12px {
    color: var(--shark);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 700;
  }
  
  .nunitosans-semi-bold-black-pearl-12px-2 {
    color: var(--black-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xl);
  }
  
  .nunitosans-semi-bold-black-pearl-20px {
    color: var(--black-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  
  .nunitosans-normal-silver-chalice-14px {
    color: var(--silver-chalice);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-normal-cannon-black-16px {
    color: var(--cannon-black);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxxxl);
  }
  
  .manrope-normal-cannon-black-16px-2 {
    color: var(--cannon-black);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-light-cultured-pearl-14px {
    color: var(--cultured-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 300;
  }
  
  .nunitosans-semi-bold-black-pearl-16px {
    color: var(--black-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxxl);
    font-style: normal;
    font-weight: 600;
  }
  
  .nunitosans-semi-bold-black-pearl-15px {
    color: var(--black-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxl);
    font-style: normal;
    font-weight: 600;
  }
  
  .manrope-semi-bold-haiti-14px {
    color: var(--haiti);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 600;
  }
  
  .nunitosans-normal-mountain-mist-12px {
    color: var(--mountain-mist);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-forest-green-12px {
    color: var(--forest-green);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-melanzane-18px-2 {
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: 18px;
  }
  
  .nunitosans-black-venus-20px {
    color: var(--venus);
    font-family: var(--font-family-nunito_sans);
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
  }
  
  .nunitosans-normal-orange-red-16px {
    color: var(--orange-red);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-melanzane-20px {
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-mountain-mist-17px {
    color: var(--mountain-mist);
    font-family: var(--font-family-nunito_sans);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-normal-forest-green-14px {
    color: var(--forest-green);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-medium-black-pearl-20px {
    color: var(--black-pearl);
    font-family: var(--font-family-manrope);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
  
  .manrope-bold-shark-17px {
    color: var(--shark);
    font-family: var(--font-family-manrope);
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
  }
  
  .nunitosans-normal-melanzane-17px-2 {
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: 17px;
  }
  
  .nunitosans-black-venus-19px {
    color: var(--venus);
    font-family: var(--font-family-nunito_sans);
    font-size: 19px;
    font-style: normal;
    font-weight: 900;
  }
  
  .nunitosans-normal-orange-red-15px {
    color: var(--orange-red);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-melanzane-19px {
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-medium-venus-13px {
    color: var(--venus);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 500;
  }
  
  .manrope-normal-forest-green-13px {
    color: var(--forest-green);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-medium-black-pearl-19px {
    color: var(--black-pearl);
    font-family: var(--font-family-manrope);
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
  }
  
  .nunitosans-normal-black-pearl-9px-2 {
    color: var(--black-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: 9px;
  }
  
  .manrope-bold-shark-15px {
    color: var(--shark);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxxl);
    font-style: normal;
    font-weight: 700;
  }
  
  .nunitosans-normal-silver-chalice-13px {
    color: var(--silver-chalice);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-orange-red-13px-2 {
    color: var(--orange-red);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxl);
  }
  
  .manrope-bold-black-pearl-14px {
    color: var(--black-pearl);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 700;
  }
  
  .nunitosans-normal-black-pearl-10px-2 {
    color: var(--black-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-m);
  }
  
  .nunitosans-semi-bold-black-pearl-11px {
    color: var(--black-pearl);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 600;
  }
  
  .nunitosans-normal-forest-green-16px-2 {
    color: var(--forest-green);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxxl);
  }
  
  .nunitosans-normal-mountain-mist-16px-2 {
    color: var(--mountain-mist);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxxl);
  }
  
  .nunitosans-normal-shark-16px-2 {
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxxl);
  }
  
  .manrope-light-cultured-pearl-14px {
    color: var(--cultured-pearl);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 300;
  }
  
  .manrope-light-cultured-pearl-12px {
    color: var(--cultured-pearl);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 300;
  }
  
  .nunitosans-normal-star-dust-14px {
    color: var(--star-dust);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-forest-green-15px-2 {
    color: var(--forest-green);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxl);
  }
  
  .nunitosans-normal-mountain-mist-15px-2 {
    color: var(--mountain-mist);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxl);
  }
  
  .nunitosans-normal-shark-15px-2 {
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxxl);
  }
  
  .nunitosans-bold-cannon-black-11px {
    color: var(--cannon-black);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
  }
  
  .manrope-light-cultured-pearl-11px-2 {
    color: var(--cultured-pearl);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-l);
  }
  
  .manrope-semi-bold-cultured-pearl-11px {
    color: var(--cultured-pearl);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 600;
  }
  
  .manrope-normal-cultured-pearl-11px {
    color: var(--cultured-pearl);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 400;
  }
  
  .nunitosans-normal-star-dust-10px {
    color: var(--star-dust);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
  }
  
  .manrope-bold-shark-14px-2 {
    color: var(--shark);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxl);
  }
  
  .nunitosans-bold-cannon-black-10px {
    color: var(--cannon-black);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
  }
  
  .manrope-light-cultured-pearl-9px {
    color: var(--cultured-pearl);
    font-family: var(--font-family-manrope);
    font-size: 9px;
    font-style: normal;
    font-weight: 300;
  }
  
  .manrope-medium-black-10px {
    color: var(--black);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 500;
  }
  
  .manrope-medium-shark-16px-2 {
    color: var(--shark);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxxxl);
  }
  
  .nunitosans-normal-orange-red-12px-2 {
    color: var(--orange-red);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xl);
  }
  
  .nunitosans-bold-black-14px {
    color: var(--black);
    font-family: var(--font-family-nunito_sans);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 700;
  }
  
  .manrope-medium-black-14px {
    color: var(--black);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 500;
  }
  
  .manrope-normal-white-12px-2 {
    color: var(--white);
    font-family: var(--font-family-manrope);
    font-size: var(--font-size-xl);
  }

  @import url("https://fonts.googleapis.com/css?family=Manrope:400,700,300,500,600|Nunito+Sans:400,700,900,300,600|Lato:500");

  
  .screen a {
    display: contents;
    text-decoration: none;
  }
  
  .container-center-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    pointer-events: none;
    width: 100%;
    margin-top: 2px;
  }
  
  .container-center-horizontal > * {
    flex-shrink: 0;
    pointer-events: auto;
  }
  
  input:focus {
    outline: none;
  }
  
  * {
    box-sizing: border-box;
  }