:root { 
  --black: #000000;
  --royal-blue: #414bdb;
  --seashell: #f1f1f1;
  --shark: #27292e;
  --silver-chalice: #acacac;
  --sonic-silver: #757575;
  --turbo: #f9e107;
  --white: #ffffff;
 

 
  --font-family-manrope: "Manrope", Helvetica;
  --font-family-nunito_sans: "Nunito Sans", Helvetica;
}
.manrope-bold-shark-19px {
  color: var(--shark);
  font-family: var(--font-family-manrope);
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
}

.manrope-normal-shark-14px {
  color: var(--shark);
  font-family: var(--font-family-manrope);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.nunitosans-normal-silver-chalice-9px {
  color: var(--silver-chalice);
  font-family: var(--font-family-nunito_sans);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.manrope-normal-silver-chalice-8px {
  color:#626262;
  font-family: var(--font-family-manrope);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  
}

.manrope-normal-silver-chalice-10px {
  color:#626262;
  font-family: var(--font-family-manrope);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  
}

.manrope-normal-shark-8px {
  color: black;
  font-family: var(--font-family-manrope);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
}

.nunitosans-normal-white-9px {
  color: var(--white);
  font-family: var(--font-family-nunito_sans);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}



.manrope-normal-shark-10px {
  color: var(--shark);
  font-family: var(--font-family-manrope);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.nunitosans-light-sonic-silver-12px {
  color: var(--sonic-silver);
  font-family: var(--font-family-nunito_sans);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}

.nunitosans-normal-shark-12px {
  color: var(--shark);
  font-family: var(--font-family-nunito_sans);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.nunitosans-semi-bold-black-12px {
  color: var(--black);
  font-family: var(--font-family-nunito_sans);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
