.cuscare_headers {
    font-family: var(--font-family-manrope);
    font-style: normal;
    font-weight: 900;
    font-size: 1.3rem;

}

.cuscare_header_2 {
    font-family: var(--font-family-manrope);
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
}

.cuscare_form {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.cuscare_input_small {
    background-color: var(--white);
    border: none;
    border-radius: 2px;
    /* box-shadow: 0px 1px 4px #00000033; */
    height: 2rem;
    margin-top: 1rem !important;
    padding-left: 10px;
    padding-right: 5px;
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    border: 1px solid;
    border-color: #afacac;
    /* margin-right: 10px; */
    width: 100%;

}

.cuscare_inputbox_phone {
    background-color: var(--white);
    border: none;
    border-radius: 2px;
    /* box-shadow: 0px 1px 4px #00000033; */
    height: 2rem;
    margin-top: 1rem !important;
    padding-left: 10px;
    padding-right: 5px;
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    border: 1px solid;
    border-color: #afacac;
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;


}

.cuscare_input_small:-webkit-autofill,
.cuscare_input_small:-webkit-autofill:hover,
.cuscare_input_small:-webkit-autofill:focus,
.cuscare_input_phone:-webkit-autofill,
.cuscare_input_phone:-webkit-autofill:hover,
.cuscare_input_phone:-webkit-autofill:focus,
.cuscare_input_big:-webkit-autofill,
.cuscare_input_big:-webkit-autofill:hover,
.cuscare_input_big:-webkit-autofill:focus,
.cuscare_descriptionbox:-webkit-autofill,
.cuscare_descriptionbox:-webkit-autofill:hover,
.cuscare_descriptionbox:-webkit-autofill:focus {

  -webkit-text-fill-color: rgb(20, 19, 19);
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}
  
  .cuscare_inputbox_phone .prefix {
    font-weight: 300;
    font-size: 14px;
    color: rgb(114, 113, 113);
  }
  
  .cuscare_input_phone  {
    flex-grow: 1;
    font-size: 14px;
    background: #fff;
    border: none;
    outline: none;
    padding: 0.5rem;
    width: 100%;
  }
  
  .cuscare_input_small:focus-within,
  .cuscare_input_big:focus-within,
  .cuscare_inputbox_phone:focus-within {
    border-color: #777;
  }


  .cc_input_small:-webkit-autofill,
.cc_input_small:-webkit-autofill:hover,
.cc_input_small:-webkit-autofill:focus,
.cc_input_phone:-webkit-autofill,
.cc_input_phone:-webkit-autofill:hover,
.cc_input_phone:-webkit-autofill:focus,
.cc_input_big:-webkit-autofill,
.cc_input_big:-webkit-autofill:hover,
.cc_input_big:-webkit-autofill:focus,
.cc_descriptionbox:-webkit-autofill,
.cc_descriptionbox:-webkit-autofill:hover,
.cc_descriptionbox:-webkit-autofill:focus {

  -webkit-text-fill-color: rgb(20, 19, 19);
  /* background-color:  #ffffff; */
   -webkit-box-shadow: 0 0 0px 1000px #ffffff inset; 
  
}

.cc_input_small:focus-within,
  .cc_input_big:focus-within,
  .cc_inputbox_phone:focus-within {
    border-color: "#131A3C";
  }
  
  .cc_checkmark{
    height: 11vw;
    padding:0;
    margin:0;
  }
    .wrapper{
       height: 8vh;
       margin-top: 4vw;
        display:flex;
        justify-content:center;
        align-items:center;
      
    }.checkmark__circle{
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 5px;
        stroke-miterlimit: 10;
        stroke: #00FF00;
        fill: none;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
    }
    .cc_checkmark{
        /* width: 56px; */
        height: 56px;
        border-radius: 50%;
        display: block;
        stroke-width: 4px;
        stroke: #00FF00;
        stroke-miterlimit: 10;
        /* margin: 10% auto; */
        box-shadow: inset 0px 0px 0px #00FF00;
        animation:  ease-in-out .4s forwards, scale .3s ease-in-out .9s both
    }
        .checkmark__check{
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-dashoffset: 48;
            border-radius: 3px;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
        }@keyframes stroke{
            100%{stroke-dashoffset: 0}
        }@keyframes scale{
            0%, 100%{transform: none}50%{transform: scale3d(1.1, 1.1, 1)}
        }
        @keyframes fill{100%{box-shadow: inset 0px 0px 0px 30px #7ac142}
    }
 

.cuscare_input_big {
    background-color: var(--white);
    border: none;
    border-radius: 2px;
    /* box-shadow: 0px 1px 4px #00000033; */
    height: 2rem;
    /* margin-top: 1rem !important; */
    padding-left: 10px;
    padding-right: 5px;
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    /* margin-right: 10px; */

    border: 1px solid;
    border-color: #afacac;

}

.cuscare_descriptionbox {
    height: 200px;
    width: 100%;
    font-family: var(--font-family-nunito_sans);
    font-weight: 400;
    font-style: normal;
    padding: 10px;
    border: 1px solid;
    border-radius: 3px;
    border-color: #afacac;
    
}

.cuscare_descriptionbox ::placeholder {
    font-family: var(--font-family-nunito_sans);
    font-weight: 600;
    font-style: normal;
    color: #959595;
    white-space: nowrap;
}

.submit_msg_cuscare {

    background-color: var(--turbo);
    font-family: var(--font-family-nunito_sans);
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
    border-radius: 5px;
    margin-top: 20px;
    padding: 5px 10px 5px 10px;
    font-size: 0.9rem;
}

.cuscare_lottie {
    width: 50vw;
}


@media screen and (max-width: 427px) {
    body {
        background: white !important;
    }

    .cuscare_headers {
        font-size: 1rem;
    }

    .cuscare_header_2 {
        font-size: 0.8rem;
    }

    .cuscare_lottie {
        width: 100%;
        margin-top: 20px;
    }

    /* .cuscare_form{

    } */

    .cuscare_descriptionbox {
        font-size: 14px;
        height: 250px;
    }

    /* .cuscare_descriptionbox ::placeholder {

    } */

    .submit_msg_cuscare {
        font-size: 0.8rem;
    }



}

@media screen and (max-width: 799px) and (min-width: 428px) {

    body {
        background: white !important;
    }

    .cuscare_headers {
        font-size: 1.3rem;
    }

    .cuscare_header_2 {
        font-size: 0.9rem;
    }

    .cuscare_lottie {
        width: 100%;
        margin-top: 20px;
    }

    .cuscare_descriptionbox {
        font-size: 14px;
        height: 300px;
    }

}

@media screen and (max-width: 1280px) and (min-width: 800px) {


    .cuscare_headers {
        font-size: 1.3rem;
    }

    .cuscare_header_2 {
        font-size: 1rem;
    }

    .cuscare_descriptionbox {
        height: 170px;

    }



}

@media screen and (min-width: 1920px) {


    .cuscare_headers {
        font-size: 1.5rem;
    }

    .cuscare_header_2 {
        font-size: 1.1rem;
    }


}
/* New Customer Care */
.cc_custcare {
    font-family: "Manrope", sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
  }