.replcmnt_headers {
    font-family: var(--font-family-manrope);
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;

}

.replcmnt_message {
    font-family: var(--font-family-nunito_sans);
    font-weight: 600;
    font-style: normal;
    white-space: nowrap;
    padding: 0.8rem;
}

.descriptionbox ::placeholder {
    font-family: var(--font-family-nunito_sans);
    font-weight: 600;
    font-style: normal;
    color: #959595;
    white-space: nowrap;
}

.descriptionbox {
    height: 300px !important;
    width: 100%;
    font-family: var(--font-family-nunito_sans);
    font-weight: 600;
    font-style: normal;
    padding: 10px;
    border: 1px solid;
    border-radius: 3px;
    border-color: #d2cece;


}

.Replacement_form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.submit_btn_replcmnt:disabled {
    background-color: rgb(135, 133, 133);
    opacity: 0.5;
    cursor: not-allowed
}

.submit_btn_replcmnt {
    align-self: flex-end;
    background-color: var(--royal-blue);
    color: var(--white);
    font-family: var(--font-family-nunito_sans);
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
    border-radius: 5px !important;
    margin-top: 20px;
    padding: 5px 20px 5px 20px;
}

.table_header_font {
    font-family: var(--font-family-nunito_sans) !important;
    font-weight: 600 !important;
    font-size: 1.2rem !important;
    color: white !important;
    text-transform: capitalize !important;
    letter-spacing: 0 !important;
    white-space: nowrap !important;
    height: 50px;

}

.btn_in {
    align-self: flex-end;
    background-color: transparent;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid;
    align-self: center;
    padding: 0px 10px;
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 700;
}

.font_data_rpl {
    font-size: 1rem;
}

.data_rplcmnt {
    font-size: 1rem;
}

.row_height {
    padding-top: 50px !important;
    padding-bottom: 40px !important;
    height: 130px !important;
}

.btn_cus_care {
    font-family: var(--font-family-nunito_sans);
    background-color: #ECE9D2;
    border-radius: 5px;
    align-self: flex-end;
    font-weight: 700;
    padding: 4px 20px;
    font-size: 1rem;

}


.descriptionbox_mob {
    height: 200px !important;
    width: 100%;
    font-family: var(--font-family-nunito_sans);
    font-weight: 600;
    font-style: normal;
    background: #F5f5f5;
    padding: 10px;

}

.submit_btn_replcmnt_mob:disabled {
    background-color: rgb(177, 173, 173);
    opacity: 0.3;
}

.submit_btn_replcmnt_mob {
    background-color: var(--white-rock);
    font-family: var(--font-family-nunito_sans);
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
    border-radius: 5px;
    margin-top: 20px;
    padding: 5px 10px 5px 10px;
    text-transform: uppercase;
    align-self: center;
}

.btn_in_mob {
    background-color: transparent;
    border-radius: 3px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid;
    align-self: center;
    padding: 0px 5px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem;
}

.btn_cus_care_mob {
    font-family: var(--font-family-nunito_sans);
    background-color: #ECE9D2;
    border-radius: 3px;
    font-weight: 600;
    padding: 4px 20px;

}

.orderdataL_mob {
    margin-top: 7px;
    font-family: var(--font-family-nunito_sans);
    font-weight: 600;
}

.orderdataLS_mob {
    margin-top: 10px;
    font-family: var(--font-family-nunito_sans);
    font-weight: 800;
}

.orderdataR_mob {
    margin-top: 7px;
    font-family: var(--font-family-nunito_sans);
    font-weight: 600;
    color: #5A5A5D;
}

.orderdataRR_mob {
    margin-top: 10px;
    font-family: var(--font-family-nunito_sans);
    font-weight: 700;
    opacity: 0.8;
}

.orderdataRRDD_mob {
    margin-top: 30px;
    font-family: var(--font-family-nunito_sans);
    font-weight: 600;
    color: #5A5A5D;
}

/* modals */

.RequestMessage_lottie {
    height: 200px;
    position: absolute;
}

.RMtextbox {
    margin-top: 150px;
}

.m1font {
    font-size: 18px;
}


.m1closeBtn {
    font-size: 14px !important;
    height: 2rem !important;
}

.refundCancelBtn {
    font-size: 0.8rem !important;
}

.refundProceedBtn {
    font-size: 0.8rem !important;
}

.RplaccntModal {
    padding: 70px 80px !important;
}

.inputbox_addAccDetails {
    background-color: var(--white);
    border: none;
    border-radius: 2px;
    box-shadow: 0px 1px 4px #00000033;
    height: 2rem;
    padding-left: 10px;
    padding-right: 5px;
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    margin-top: 15px;
}

.bankAccntFont {
    margin-top: 20px;
}

.accnt_btn {

    font-size: 0.9rem !important;
    font-weight: 400 !important;
    height: 2rem !important;

}

.RefundedMessage_lottie {
    height: 150px;
    position: absolute;
}

.RefundedMessageTextbox {
    margin-top: 180px;

}

@media screen and (max-width: 3000px) and (min-width: 1920px) {

    .replcmnt_headers {
        font-size: 2rem;
    }

    .replcmnt_message {
        font-size: 1.5rem;
        padding: 1rem;
    }

    .descriptionbox {
        font-size: 1.5rem !important;
        height: 450px !important;
    }

    .submit_btn_replcmnt {
        font-size: 1.5rem;
    }

    .table_header_font {
        font-size: 1.6rem !important;
        height: 60px;

    }

    .font_data_rpl {
        font-size: 1.4rem;
    }

    .row_height {
        padding-top: 60px !important;
        padding-bottom: 40px !important;
        height: 150px !important;
    }

    .btn_in {
        font-size: 1.1rem;
        padding: 6px 10px;
    }

    .btn_cus_care {
        font-size: 1.5rem;
    }


}

@media screen and (max-width: 1279px) and (min-width: 1024px) {

    .replcmnt_headers {
        font-size: 1.1rem;
    }

    .replcmnt_message {
        font-size: 0.8rem;
        padding: 0.6rem;
    }

    .descriptionbox {
        font-size: 0.8rem !important;
    }

    .submit_btn_replcmnt {
        font-size: 0.6rem;
        padding: 5px 10px 5px 10px;
    }

    .table_header_font {
        font-size: 0.9rem !important;
        height: 40px;

    }

    .font_data_rpl {
        font-size: 0.7rem;
    }

    .row_height {
        padding-top: 35px !important;
        padding-bottom: 20px !important;
        height: 100px !important;
    }

    .btn_in {
        font-size: 0.7rem;
        padding: 0px 5px;
    }

    .btn_cus_care {
        font-size: 0.9rem;
    }

}

@media screen and (max-width: 1023px) and (min-width: 800px) {

    .replcmnt_headers {
        font-size: 1.1rem;
    }

    .replcmnt_message {
        font-size: 0.9rem;
        padding: 0.6rem;
    }

    .descriptionbox {
        font-size: 0.9rem !important;
        height: 200px !important;
    }

    .submit_btn_replcmnt {
        font-size: 0.7rem;
        padding: 5px 10px 5px 10px;
    }

    .table_header_font {
        font-size: 0.8rem !important;
        height: 40px !important;
        padding-inline-start: 5px !important;
        padding-inline-end: 5px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;

    }

    .font_data_rpl {
        font-size: 0.7rem;
    }

    .row_height {
        padding-top: 35px !important;
        padding-bottom: 20px !important;
        height: 100px !important;
        padding-inline-start: 5px !important;
        padding-inline-end: 5px !important;

    }

    .btn_in {
        font-size: 0.7rem;
        padding: 0px 5px;
    }

    .btn_cus_care {
        font-size: 0.9rem;
    }

    .m1font {
        font-size: 18px;
    }


}

@media screen and (max-width: 799px) {

    .m1font {
        font-size: 14px;
    }

    .RplaccntModal {
        padding: 50px 20px !important;
    }

    .inputbox_addAccDetails {
        font-size: 0.8rem;
        height: 1.8rem;
        border-radius: 1px;
    }

    .bankAccntFont {
        font-size: 0.8rem;

    }

    .accnt_btn {

        font-size: 0.8rem !important;
        height: 1.8rem !important;
    }

    .RefundedMessageTextbox {
        margin-top: 50px;

    }

}