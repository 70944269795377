@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;500;600;800;900&display=swap');

/* Tailwind filterpage */
@tailwind base;
@tailwind components;
@tailwind utilities;

.nxt_subheaders {
  @apply ml-4 mb-1 font-manrope text-left font-bold text-base uppercase sm:text-lg lg:text-xl xl:text-2xl;
}

.ccr_divider {
  @apply mb-3 border-y border-yellowB bg-yellowB;
  height: 2px;
}
.cne_border {
  @apply rounded-brB shadow-bsCard2;
}
.grey_button {
  @apply items-center justify-center rounded-brBs  bg-greyB uppercase  text-blueB3 focus:outline-none hover:opacity-60;
}
.large_button {
  @apply w-full  rounded-brBs h-14 md:flex md:items-center md:justify-center md:gap-2 md:bg-yellowB hover:opacity-60;
}
.fixed_button {
  @apply fixed bottom-0  flex h-16 w-full items-center justify-center gap-2 bg-yellowB;
}

.filteredoptions {
  @apply mr-2 mt-4 font-manrope whitespace-nowrap rounded-2xl border border-yellowB px-3 py-1 text-center text-xs sm:text-sm md:text-base;
}
.hidefilteredoptions::-webkit-scrollbar {
  display: none;
  width: 0;
}

.hidefilteredoptions {
  overflow-y: none;
  overflow-x: scroll;
}
.inputErrors {
  @apply text-sm text-redB opacity-50 ml-3 text-left pt-1;
}

.fpyellowBtn {
  @apply rounded-brBs bg-yellowB font-manrope  font-bold text-base uppercase;
}

.fpyellowBtnBorder {
  @apply rounded-brBs border border-yellowB text-xs font-bold font-manrope uppercase shadow-bsCard3 focus:outline-none;
}

.empty-space {
  pointer-events: none;
}

/* ProductCardScroll */
.ccr_fontpart1 {
  @apply font-manrope text-sm  sm:text-base xl:text-base;
}
.ccr_fontpart2 {
  @apply font-bold font-manrope text-base sm:text-lg;
}
.ccr_fontpart3 {
  @apply font-manrope text-xs sm:text-sm md:text-base xl:text-base;

  // @apply text-xs sm:text-sm md:text-xs xl:text-sm
}

.cne_fontpart2 {
  @apply font-manrope font-normal text-lg sm:text-lg lg:text-xl xl:text-2xl;
}
.cne_fontpart3 {
  @apply font-manrope text-sm md:text-xs xl:text-sm;
}
.cne_fontpart4 {
  /* Delivery Details */

  @apply font-manrope text-base sm:text-lg md:text-sm lg:text-lg;
}

.PCSdata {
  @apply h-24 sm:h-28 md:h-32;
}

/* Product Detail Page */

.pd_font12 {
  @apply font-manrope text-xs sm:text-sm md:text-xs lg:text-sm xl:text-base;
}
.pd_font14 {
  @apply font-manrope text-sm sm:text-base md:text-sm lg:text-base;
}
.pd_font16 {
  @apply font-manrope text-base sm:text-lg md:text-sm lg:text-base;
}
.pd_font18 {
  @apply font-manrope text-lg sm:text-xl md:text-lg lg:text-xl;
}
.pd_font20 {
  @apply font-manrope text-xl sm:text-2xl  md:text-xl lg:text-2xl xl:text-3xl;
}
.pd_detailHeight div {
  @apply my-4 md:my-2;
}
.pd_vehiclespec p {
  @apply mx-1 whitespace-nowrap;
}

.price_font14-18 {
  @apply font-manrope text-sm md:text-base  lg:text-base xl:text-lg;
}
.mainprice_font16-20 {
  @apply font-manrope text-base md:text-lg  lg:text-lg xl:text-xl;
}
.ccr_inputbox {
  @apply w-full text-black rounded-brBs focus:outline-none;
}
.ccr_inputbox:-webkit-autofill,
.ccr_inputbox:-webkit-autofill:hover,
.ccr_inputbox:-webkit-autofill:focus,
.ccr_inputbox:-webkit-autofill,
.ccr_inputbox:-webkit-autofill:hover,
.ccr_inputbox:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: inset 0 0 0px 9999px white, 0px 1px 1px rgba(0, 0, 0, 0.25);
}

/*checkmark animation*/

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 5px;
  stroke-miterlimit: 10;
  stroke: #1abc35;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.cne_checkmark {
  height: 35px;
  border-radius: 50%;
  display: block;
  stroke-width: 4px;
  stroke: #1abc35;
  stroke-miterlimit: 10;

  box-shadow: inset 0px 0px 0px #1abc35;
  animation: ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  border-radius: 3px;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.hpAdsImg {
  @apply rounded-brB drop-shadow-dsCard2;
}
/* Address */

.add_font12 {
  /* pay_on_verification,order_placed,order_not_placed */
  @apply font-manrope text-xs sm:text-sm md:text-xs xl:text-sm;
}
.add_font14 {
  /* Delivery Details,pay_on_verification,order_placed */

  @apply font-manrope text-sm sm:text-base md:text-sm xl:text-base capitalize;
}
.add_font16 {
  /*pay_on_verification*/
  @apply font-manrope text-base sm:text-lg md:text-base lg:text-lg;
}
.add_font18 {
  /* Delivery Details ,order_placed,order_not_placed cart_notempty*/
  @apply font-manrope text-lg sm:text-xl md:text-sm lg:text-base xl:text-lg;
}
.add_font20 {
  /* Delivery Details ,order_placed,order_not_placed*/
  @apply font-manrope text-xl sm:text-2xl md:text-base lg:text-lg xl:text-xl;
}
.add_font18-24 {
  /* Delivery Details  */
  @apply font-manrope text-lg sm:text-xl md:text-lg lg:text-xl xl:text-2xl;
}

/*pay_on_verification,order_not_placed*/

.pay_font14-20 {
  @apply font-manrope font-bold text-sm md:text-base lg:text-lg xl:text-xl;
}
.popup_font1618_20 {
  @apply font-manrope text-base sm:text-lg md:text-base lg:text-lg;
}
.popup_font16_18 {
  @apply font-manrope text-sm sm:text-base md:text-sm lg:text-base;
}

/* Modals */

.ModalyellowBtn {
  @apply rounded-brBs border border-yellowB bg-yellowB  font-bold text-sm uppercase shadow-bsCard3 focus:outline-none hover:opacity-60;
}

.ModalyellowBtnBorder {
  @apply rounded-brBs border border-yellowB text-sm font-bold uppercase shadow-bsCard3 focus:outline-none hover:opacity-60;
}

.cne_quantitybutton {
  background-color: #f5f4ff;
  height: 22px;
  border-radius: 5px;
  padding: 0px;
  width: 33px;
  min-width: 28px;
  margin-inline-start: 0px;
  -webkit-margin-start: 0px;
  align-items: baseline;
  // box-shadow: 0px 1px rgba(0, 0, 0, 0.25) !important;
  @apply shadow-bsCard2;
}
@media screen and (max-width: 427px) {
  .cne_quantitybutton {
    background-color: #f5f4ff;
    height: 23px;
    border-radius: 5px;
    padding: 0px;
    width: 22px;
    min-width: 19px;
    margin-inline-start: 0px;
    -webkit-margin-start: 0px;
    align-items: baseline;
    // box-shadow: 0px 1px rgba(0, 0, 0, 0.25) !important;
    @apply shadow-bsCard2;
  }
}

.cne_quantitybutton:disabled {
  background-color: #f7f7f7;
  color: #bdbdbd;
  cursor: not-allowed;
  box-shadow: none;
}

.cne_quantitybutton:disabled:hover {
  background-color: #fff;
}

.cne_quantitybutton:hover {
  background: #e1e1e1;
}

.cne_quantity {
  background-color: var(--white);
  height: 20px;
  border: 0px;
  padding: 0px;
  text-align: center;
  width: 19px;
  min-width: 18px;
  margin-inline-start: 0px;
  -webkit-margin-start: 0px;
}
.cne_quantity {
  background-color: var(--white);
  height: 20px;
  border: 0px;
  padding: 0px;
  text-align: center;
  width: 19px;
  min-width: 18px;
  margin-inline-start: 0px;
  -webkit-margin-start: 0px;
}
@media screen and (max-width: 427px) {
  .cne_quantity {
    background-color: var(--white);
    height: 20px;
    border: 0px;
    padding: 0px;
    text-align: center;
    width: 12px;
    min-width: 11px;
    margin-inline-start: 0px;
    -webkit-margin-start: 0px;
  }
}

/* ------ */

.font_Manrope {
  font-family: 'Manrope', sans-serif;
}
.font_Manrope400 {
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
}
.font_Manrope700 {
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
}

.font_Nunito {
  font-family: 'Nunito Sans', sans-serif;
  text-transform: capitalize;
}

.font_NunitoA {
  font-family: 'Nunito Sans', sans-serif;
}

.font_NunitoUp {
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
}
.font_Nunito::placeholder {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: lighter;
  text-transform: capitalize;
  color: #37393d;
  letter-spacing: 0px;
}

.mobile_only {
  display: none;
}

.plus_icon_recent {
  display: none;
}

.layout_grid {
  grid-template-areas: 'item_grid';
}

.title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.titledetails {
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  margin-left: 4px;
}
.recentlogo {
  height: 30px;
  object-fit: cover;
}
.recent_font {
  font-size: 20px;
  margin-left: 1rem;
}

//Emptystate

.flexbox_Empty_State {
  margin-top: 80px;
}
.savederrorjson {
  height: 200px;
  width: 200px;
}
.textbox_Empty_State {
  margin-top: 50px;
  margin-bottom: 30px;
}
.bold_text_Empty_State {
  font-size: 1rem;
}
.text2_Empty_State {
  margin-top: 5px;
  font-size: 1rem;
}
.btn_empty_state :hover {
  background-color: #ffffff;
}

.btn_empty_state {
  background-color: var(--turbo);
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
  font-size: 1.1rem;
}

//toast
.toastbox {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 10px;
  padding: 5px;
}

.toastfont {
  font-size: 1rem;
  margin-left: 1rem;
  margin-right: 2rem;
}
.toasticon {
  size: 1.4rem;
}

.toastclose {
  size: 1.1rem;
}

.customselectFont {
  color: #000;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  font-size: 0.9rem;
}

.FPSelectMob {
  background-color: transparent;
  width: 100%;
}
.FPSelectMob:first-of-type {
  margin-top: 0px;
}

@media screen and (max-width: 427px) {
  .desktop_only {
    display: none !important ;
  }

  .mobile_only {
    display: block;
  }

  .layout_bg {
    background-color: #ffffff !important;
  }

  // .layout_grid {
  //     grid-template-areas:
  //         "item_grid"
  //         "recent_items"
  //         "ad_image";
  // }

  .item_grid {
    padding: 0 !important;
  }

  .sorry {
    font-size: 18px !important;
    white-space: nowrap;
  }
  .sorry2 {
    font-size: 10px !important;
  }
  .emptylottiebox {
    height: 240px !important;
    width: 200px !important;
  }

  .filter_itemDisplay {
    margin: 4vw;
    margin-top: 0px;
    width: -webkit-fill-available;
  }

  .items_grid {
    grid-template-columns: 1fr 1fr;

    margin-top: 20px;
  }

  .item_box:hover {
    box-shadow: none !important;
    transition-delay: none !important;
  }

  .image_transition:hover {
    -moz-transform: none;
    -webkit-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;

    -webkit-transition: transform none;
    -moz-transition: transform none;
    -ms-transition: transform none;
  }

  .item_box {
    padding: 4px !important;
    margin: 1px !important;
  }

  .item_box_image {
    margin: 0 !important;
    transition: none !important;
    padding: 8px !important;
    border-radius: 5px;
    height: 38vw;
    width: 38vw;
    min-width: 100px;
    min-height: 100px;
    box-shadow: 0px 0px 2px #27292e33;
  }

  .rating_box {
    display: none !important;
  }

  .companyname {
    padding-top: 10px;
    font-size: 16px;
  }
  .FPcompanyname {
    font-size: 20px;
  }
  .FPProductname {
    font-size: 18px;
  }
  .FPsellingPrice {
    font-size: 20px;
  }
  .FPcostPrice {
    font-size: 21px;
  }
  .FPdiscountPercentage {
    font-size: 14px;
  }

  .ratingbox {
    font-size: 9px;
  }
  .reviewCount {
    font-size: 9px;
  }
  .title {
    font-size: 14px;
    width: 38vw;
  }
  .titledetails {
    font-size: 12px;
  }
  .sellingPrice {
    display: none;
  }
  .costPrice {
    font-size: 15px;
    padding-left: 0 !important;
  }
  .discountPercentage {
    font-size: 9px;
  }
  .MobSellingPrice {
    display: block !important;
    font-size: 14px;
  }

  .recent_section_box {
    padding-top: 0 !important;
    width: 90vw !important;
  }
  .recent_font {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
    margin-left: 0;
  }
  .recent_scrollbox {
    padding: 2px;
  }

  .recent_image {
    margin: 0 !important;
    transition: none !important;
    padding: 8px !important;
    border-radius: 5px;
    height: 200px;
    width: 160px;
    object-fit: cover;
    box-shadow: 0px 0px 2px #27292e33;
  }
  .plus_icon {
    height: 25px !important;
    min-width: 25px !important;
    font-size: 6px !important;
  }

  // footer
  .footer_font {
    font-size: 12px;
  }
  .footer_font2 {
    font-size: 10px;
  }
  .footer_font3 {
    font-size: 7px;
  }
  .refundPolicy {
    font-size: 0.7rem;
  }
  .flexbox_Empty_State {
    margin-top: 50px;
  }
  .savederrorjson {
    height: 150px;
    width: 150px;
  }
  .textbox_Empty_State {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .bold_text_Empty_State {
    font-size: 0.8rem;
  }
  .text2_Empty_State {
    font-size: 0.8rem;
  }
  .btn_empty_state {
    background-color: var(--royal-blue);
    color: #ffffff;
    font-size: 0.9rem;
    padding: 7px;
  }
  // .toastbox {
  // z-index: 5;
  // height: 1rem;
  // }
  .toastfont {
    font-size: 1rem;
  }

  .FPSelectMob {
    margin-top: 3vh;
  }

  .FPSelectMob .customselectFont {
    font-size: 16px;
    padding: 10px;
  }

  .FPSelectMob .FaChevronDownsize {
    font-size: 10px;
  }
}

@media screen and (max-width: 799px) and (min-width: 428px) {
  .desktop_only {
    display: none !important ;
  }

  .mobile_only {
    display: block;
  }

  .layout_bg {
    background-color: #ffffff !important;
  }

  .layout_grid {
    grid-template-areas:
      'item_grid'
      'recent_items'
      'ad_image';
  }
  .sorry {
    font-size: 18px !important;
    white-space: nowrap;
  }
  .sorry2 {
    font-size: 10px !important;
  }
  .emptylottiebox {
    height: 250px !important;
    width: 200px !important;
  }

  .filter_itemDisplay {
    margin: 4vw;
    margin-top: 0px;
    width: -webkit-fill-available;
  }
  .items_grid {
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
  }

  .item_box:hover {
    box-shadow: none !important;
    transition-delay: none !important;
  }

  .image_transition:hover {
    -moz-transform: none;
    -webkit-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;

    -webkit-transition: transform none;
    -moz-transition: transform none;
    -ms-transition: transform none;
  }

  .item_box {
    padding: 5px !important;
    margin: 1px !important;
  }

  .item_box_image {
    margin: 0 !important;
    transition: none !important;
    padding: 8px !important;
    border-radius: 5px;
    // height: 160px;
    // width: 153px;
    box-shadow: 0px 0px 2px #27292e33;
    height: 30vw;
    width: 30vw;
    min-width: 170px;
    min-height: 170px;
  }

  .rating_box {
    display: none !important;
  }

  .companyname {
    padding-top: 10px;
    font-size: 16px;
  }
  .FPcompanyname {
    font-size: 22px;
  }
  .FPProductname {
    font-size: 20px;
  }
  .FPsellingPrice {
    font-size: 22px;
  }
  .FPcostPrice {
    font-size: 23px;
  }
  .FPdiscountPercentage {
    font-size: 16px;
  }

  .ratingbox {
    font-size: 9px;
  }
  .reviewCount {
    font-size: 9px;
  }
  .title {
    font-size: 14px;
    width: 153px;
  }
  .titledetails {
    font-size: 12px;
  }
  .sellingPrice {
    display: none;
  }
  .costPrice {
    font-size: 15px;
    padding-left: 0 !important;
  }
  .discountPercentage {
    font-size: 9px;
  }
  .MobSellingPrice {
    display: block !important;
    font-size: 14px;
  }

  .recent_section_box {
    padding-top: 0 !important;
  }
  .recent_font {
    font-size: 18px;
    margin-bottom: 20px !important;
    text-align: center;
    margin-left: 0;
  }
  .recent_scrollbox {
    padding: 2px;
  }

  .recent_image {
    margin: 0 !important;
    transition: none !important;
    padding: 8px !important;
    border-radius: 5px;
    height: 200px;
    width: 160px;
    object-fit: cover;
    box-shadow: 0px 0px 2px #27292e33;
  }
  .plus_icon {
    height: 25px !important;
    min-width: 25px !important;
    font-size: 6px !important;
  }
  // footer
  .footer_font {
    font-size: 13px;
  }
  .footer_font2 {
    font-size: 12px;
  }
  .footer_font3 {
    font-size: 8px;
  }
  .refundPolicy {
    font-size: 0.8rem;
  }
  .flexbox_Empty_State {
    margin-top: 50px;
  }
  .savederrorjson {
    height: 170px;
    width: 170px;
  }
  .textbox_Empty_State {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .bold_text_Empty_State {
    font-size: 0.9rem;
  }
  .text2_Empty_State {
    font-size: 0.9rem;
  }
  .btn_empty_state {
    background-color: var(--royal-blue);
    color: #ffffff;
    font-size: 0.9rem;
    padding: 7px;
  }

  .toastfont {
    font-size: 1rem;
  }

  .FPSelectMob {
    margin-top: 4vh;
  }

  .FPSelectMob .customselectFont {
    font-size: 18px;
    padding: 10px;
  }

  .FPSelectMob .FaChevronDownsize {
    font-size: 12px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 800px) {
  .items_grid {
    grid-template-columns: 1fr 1fr;
  }

  .topnav {
    padding-left: 8vw;
    padding-right: 5.5vw;
  }

  .SignCartMore {
    width: 27vw;
  }
  .signcartmorefont {
    font-size: 14px !important;
  }

  .bottomNav {
    padding-left: 0;
    padding-right: 0;
  }
  .titles {
    font-size: 12px;
  }

  .filter_itemDisplay {
    margin-top: 2vw;
    padding-left: 8vw;
    padding-right: 5.5vw;
    margin-bottom: 2rem;
  }
  .filter_box {
    margin-left: 0;
    margin-right: 0;
    min-width: 260px !important;
  }
  .emptylottiebox {
    margin-top: 100px;
    height: 300px !important;
  }
  .sorry {
    font-size: 20px !important;
  }
  .sorry2 {
    font-size: 12px !important;
  }

  .sort_box {
    height: 30px !important;
  }
  .sort_box_font {
    font-size: 1rem;
  }
  // .item_grid {
  //     // min-width: 525px;
  // }
  .item_box {
    max-width: 250px;
  }

  .item_box_image {
    height: 19vw;
    margin: 5px;
  }

  .recent_section_box {
    padding-left: 6vw;
    padding-right: 0vw;
  }
  .recent_font {
    font-size: 11px;
  }

  .recent_divider {
    margin-bottom: 6vh;
  }
  .recent_image {
    height: 200px;
    width: 200px;
  }

  .companyname {
    font-size: 16px;
  }
  .ratingbox {
    font-size: 9px;
  }
  .reviewCount {
    font-size: 9px;
  }
  .title {
    font-size: 14px;
    width: 20vw;
  }

  .sellingPrice {
    font-size: 15px;
  }
  .costPrice {
    font-size: 16px;
  }
  .discountPercentage {
    font-size: 9px;
  }
  .plus_icon_recent {
    display: none !important;
  }
  .plus_icon {
    height: 25px !important;
    min-width: 25px !important;
    font-size: 6px !important;
  }

  .footer1 {
    padding: 2vw 7vw 2vw 7vw;
  }
  .footerlogo {
    height: 6vh;
  }
  .playstorelogo {
    height: 4vh;
  }

  .footeraddress {
    margin-left: 25px;
  }
  .footerstackwidth {
    width: 110px;
  }

  .footerheadfont {
    font-size: 11px;
  }
  .footeritemsfont {
    font-size: 10px;
  }
  .footeradressfont {
    font-size: 11px;
  }
  .footer2 {
    padding: 2rem;
    margin-left: 2vw;
    margin-right: 5vw;
  }
  // .girdgap {
  // }
  .footer3help {
    margin-right: 50px;
  }
  .footer3_2020 {
    margin-left: 10px;
  }
  .footer_size {
    font-size: 9px;
  }
  .footer_image {
    height: 9px;
    padding-right: 4px;
  }
  .refundPolicy {
    font-size: 0.9rem;
  }
  .flexbox_Empty_State {
    margin-top: 50px;
  }
  .savederrorjson {
    height: 150px;
    width: 150px;
  }
  .textbox_Empty_State {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .bold_text_Empty_State {
    font-size: 0.8rem;
  }
  .text2_Empty_State {
    font-size: 0.8rem;
  }
  .btn_empty_state {
    font-size: 0.9rem;
    padding: 7px;
  }
  .customfilterfont {
    font-size: 0.8rem;
  }
  .toastbox {
    height: 2.2rem;
  }
  .toastfont {
    font-size: 1rem;
  }

  .customselectFont {
    height: 2.2rem;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .items_grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .topnav {
    padding-left: 8vw;
    padding-right: 8vw;
  }

  .SignCartMore {
    width: 24vw;
  }
  .signcartmorefont {
    font-size: 14px !important;
  }

  .bottomNav {
    padding-left: 0;
    padding-right: 2.5vw;
  }
  .titles {
    font-size: 13px;
  }

  .filter_itemDisplay {
    margin-top: 2vw;
    padding-left: 8vw;
    padding-right: 8vw;
    margin-bottom: 2rem;
  }
  .filter_box {
    margin-left: 0;
    margin-right: 0;
    // width: 24vw !important;
    min-width: 250px !important;
  }

  .emptylottiebox {
    margin-top: 100px;
    height: 300px !important;
    width: 25vw !important;
  }
  .sorry {
    font-size: 24px !important;
  }
  .sorry2 {
    font-size: 12px !important;
  }
  // .item_grid {
  //     // min-width: 765px;
  // }
  .sort_box {
    height: 35px !important;
    font-size: 16px !important;
  }

  .item_box_image {
    height: 16vw;
    margin: 5px;
  }

  .recent_section_box {
    padding-left: 6vw;
    padding-right: 0vw;
  }
  .recent_font {
    font-size: 14px;
  }

  .recent_divider {
    margin-bottom: 6vh;
  }
  .recent_image {
    height: 200px;
    width: 200px;
  }

  .companyname {
    font-size: 16px;
  }
  .ratingbox {
    font-size: 9px;
  }
  .reviewCount {
    font-size: 9px;
  }
  .title {
    font-size: 14px;
    width: 14vw;
  }
  .sellingPrice {
    font-size: 15px;
  }
  .costPrice {
    font-size: 16px;
  }
  .discountPercentage {
    font-size: 9px;
  }
  .plus_icon_recent {
    display: none !important;
  }
  .plus_icon {
    height: 25px !important;
    min-width: 25px !important;
    font-size: 6px !important;
  }

  .footer1 {
    padding: 2vw 6vw 2vw 6vw;
  }
  .footerlogo {
    height: 7vh;
  }
  .playstorelogo {
    height: 4vh;
  }

  .footeraddress {
    margin-left: 90px;
  }
  .footerstackwidth {
    width: 120px;
  }

  .footerheadfont {
    font-size: 12px;
  }
  .footeritemsfont {
    font-size: 11px;
  }
  .footeradressfont {
    font-size: 12px;
  }
  .footer2 {
    padding: 2rem;
    margin-left: 2vw;
    margin-right: 6vw;
  }
  .girdgap {
    grid-gap: 4vw;
  }
  .footer3help {
    margin-right: 50px;
  }
  .footer3_2020 {
    margin-left: 10px;
  }
  .footer_size {
    font-size: 10px;
  }

  .footer_image {
    height: 10px;
    padding-right: 6px;
  }
  .refundPolicy {
    font-size: 0.9rem;
  }
  .flexbox_Empty_State {
    margin-top: 70px;
  }
  .savederrorjson {
    height: 200px;
    width: 200px;
  }
  .bold_text_Empty_State {
    font-size: 1rem;
  }
  .text2_Empty_State {
    font-size: 1rem;
  }
  .btn_empty_state {
    font-size: 1rem;
  }
  .customfilterfont {
    font-size: 0.9rem;
  }
  .toastbox {
    height: 2.5rem;
  }
  .toastfont {
    font-size: 1.1rem;
  }
  .customselectFont {
    font-size: 0.9rem;
    height: 2.2rem;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .items_grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .topnav {
    padding-left: 9.7vw;
    padding-right: 8.5vw;
  }

  .SignCartMore {
    width: 20vw;
  }
  .bottomNav {
    padding-left: 2vw;
    padding-right: 3.9vw;
  }

  .filter_itemDisplay {
    margin-top: 4vh;
    padding-left: 9.7vw;
    padding-right: 8.5vw;
    margin-bottom: 2rem;
  }
  .sort_box {
    height: 35px !important;
    font-size: 16px !important;
  }
  // .emptylottiebox {
  // margin-top: 80px;
  // }

  // .item_grid {
  // min-height: 880px;
  // min-width: 880px;
  // }

  .item_box_image {
    // height: 200px;
    height: 14vw;
    // width: 14vw;
    margin: 10px;
  }

  .recent_section_box {
    padding-left: 7vw;
    padding-right: 1vw;
  }
  .recent_font {
    font-size: 18px;
  }

  .recent_divider {
    margin-bottom: 6vh;
  }
  .recent_image {
    height: 200px;
    width: 200px;
  }

  .companyname {
    font-size: 18px;
  }
  .ratingbox {
    font-size: 9px;
  }
  .reviewCount {
    font-size: 9px;
  }
  .title {
    font-size: 17px;
    width: 15vw;
  }
  .sellingPrice {
    font-size: 16px;
  }
  .costPrice {
    font-size: 18px;
  }
  .discountPercentage {
    font-size: 10px;
  }
  .plus_icon_recent {
    display: none !important;
  }
  .footer1 {
    padding: 2vw 6vw 2vw 6vw;
  }
  .footerlogo {
    height: 8vh;
  }
  .playstorelogo {
    height: 5vh;
  }

  .footeraddress {
    margin-left: 90px;
  }
  .footerstackwidth {
    width: 120px;
  }

  .footerheadfont {
    font-size: 12px;
  }
  .footeritemsfont {
    font-size: 11px;
  }
  .footeradressfont {
    font-size: 12px;
  }
  .footer2 {
    padding: 2rem;
    margin-left: 2vw;
    margin-right: 7vw;
  }
  .girdgap {
    grid-gap: 4vw;
  }
  .footer3help {
    margin-right: 50px;
  }
  .footer3_2020 {
    margin-left: 10px;
  }
  .footer_size {
    font-size: 11px;
  }
  .customfilterfont {
    font-size: 0.9rem;
  }
  .toastbox {
    height: 2.5rem;
  }
  .toastfont {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 1600px) and (min-width: 1367px) {
  .items_grid {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
  .item_box_image {
    height: 14vw;
    // width: 14vw;
    margin: 15px;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1601px) {
  .items_grid {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }
  .item_box_image {
    height: 12vw;
    // width: 11vw;
    margin: 15px;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1367px) {
  .items_grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .topnav {
    padding-left: 8.8vw;
    padding-right: 8.5vw;
  }

  .SignCartMore {
    width: 20vw;
  }

  .bottomNav {
    padding-left: 0;
    padding-right: 1.6vw;
  }

  .filter_itemDisplay {
    margin-top: 5vh;
    padding-left: 8.8vw;
    padding-right: 8.5vw;
    margin-bottom: 2rem;
  }
  .filter_box {
    // margin-left: 2rem;
    // margin-right: 2rem;
    min-width: 300px !important;
  }

  // .item_grid {
  //     min-width: 1196px;
  // }
  // .emptylottiebox {
  // margin-top: 100px;
  // }

  // .item_box {
  // height: 380px !important;
  // width: 260px !important;
  // }

  // .item_box_image {
  // height: 12vw;
  // width: 11vw;
  // margin: 15px;
  // }

  .recent_section_box {
    padding-left: 6vw;
    padding-right: 3vw;
  }
  .recent_font {
    font-size: 24px;
  }

  .recent_divider {
    margin-bottom: 2vh;
  }

  .recent_image {
    height: 225px;
    width: 225px;
  }

  .companyname {
    font-size: 18px;
  }
  .ratingbox {
    font-size: 9px;
  }
  .reviewCount {
    font-size: 10px;
  }
  .title {
    font-size: 16px;
  }
  .sellingPrice {
    font-size: 18px;
  }
  .costPrice {
    font-size: 20px;
  }
  .discountPercentage {
    font-size: 12px;
  }
  .plus_icon_recent {
    display: none !important;
  }

  .footer1 {
    padding: 2vw 1vw 2vw 1vw;
  }
  .footerlogo {
    height: 8vh;
  }
  .playstorelogo {
    height: 5vh;
  }

  .footeraddress {
    margin-left: 160px;
  }

  .footerstackwidth {
    width: 150px;
  }

  .footerheadfont {
    font-size: 14px;
  }
  .footeritemsfont {
    font-size: 13px;
  }
  .footeradressfont {
    font-size: 14px;
  }
  .footer2 {
    padding: 4rem;
  }
  .girdgap {
    grid-gap: 4vw;
  }
  .footer3help {
    margin-left: 150px;
  }
  .footer3_2020 {
    margin-right: 170px;
  }
  .filter_select_height {
    height: 3rem !important;
  }
  .refundPolicy {
    font-size: 1.1rem;
  }
  .flexbox_Empty_State {
    margin-top: 100px;
  }
  .savederrorjson {
    height: 240px;
    width: 240px;
  }
  .bold_text_Empty_State {
    font-size: 1.2rem;
  }
  .text2_Empty_State {
    margin-top: 10px;
    font-size: 1.2rem;
  }
  .btn_empty_state {
    margin-top: 40px;
    font-size: 1.3rem;
  }
  .customfilterfont {
    font-size: 1rem;
  }
  .toastbox {
    height: 3rem;
  }
  .toastfont {
    font-size: 1.3rem;
  }
  .toasticon {
    size: 2.4rem;
  }

  .toastclose {
    size: 2.1rem;
  }
  .customselectFont {
    font-size: 1rem;
    height: 3rem;
    padding: 13px;
  }
}
@media screen and (min-width: 3001px) {
  .items_grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 3000px) and (min-width: 1921px) {
  .items_grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1921px) {
  .topnav {
    padding-left: 9.6vw;
    padding-right: 9.3vw;
    height: 90px !important;
  }
  .brakeup-logo-with-border {
    height: 3.5rem !important;
  }
  .navsearch {
    height: 35px !important;
    font-size: 16px !important;
  }

  .SignCartMore {
    width: 20vw;
  }

  .signcartmorefont {
    font-size: 20px !important;
  }
  .cartquantitypop {
    top: 17px !important;
    margin-left: 5px !important;
  }

  .bottomNav {
    padding-left: 0;
    padding-right: 1.8vw;
  }
  .titles {
    font-size: 18px;
  }

  .filter_itemDisplay {
    margin-top: 5vh;
    padding-left: 9.4vw;
    padding-right: 9.3vw;
    margin-bottom: 2rem;
  }
  .filter_box {
    // margin-left: 2rem;
    margin-right: 2rem;
    min-width: 325px !important;
  }
  // .item_grid {
  // min-width: 1490px;
  // }

  .item_box {
    // height: 400px !important;
    // width: 260px !important;
    max-width: 380px;
  }

  .item_box_image {
    // height: 220px;
    height: 12vw;
    width: 12vw;
    margin: 15px;
    max-width: 320px;
  }
  .recent_section_box {
    padding-left: 6vw;
    padding-right: 3vw;
  }
  .recent_font {
    font-size: 24px;
  }

  .recent_divider {
    margin-bottom: 2vh;
  }

  .recent_image {
    height: 240px;
    width: 240px;
  }

  .companyname {
    font-size: 18px;
  }
  .ratingbox {
    font-size: 9px;
  }
  .reviewCount {
    font-size: 10px;
  }
  .title {
    font-size: 16px;
  }
  .sellingPrice {
    font-size: 18px;
  }
  .costPrice {
    font-size: 20px;
  }
  .discountPercentage {
    font-size: 12px;
  }
  .plus_icon_recent {
    display: none !important;
  }
  .footer1 {
    padding: 2vw 2vw 2vw 2vw;
  }
  .footerlogo {
    height: 8vh;
  }
  .playstorelogo {
    height: 5vh;
  }

  .footeraddress {
    margin-left: 160px;
  }

  .footerstackwidth {
    width: 150px;
  }

  .footerheadfont {
    font-size: 14px;
  }
  .footeritemsfont {
    font-size: 13px;
  }
  .footeradressfont {
    font-size: 14px;
  }
  .footer2 {
    padding: 4rem;
  }
  .girdgap {
    grid-gap: 4vw;
  }

  .footer3help {
    margin-left: 150px;
  }
  .footer3_2020 {
    margin-right: 170px;
  }
  .filter_select_height {
    height: 3.5rem !important;
  }
  .fighgkfgkf {
    font-size: large;
  }
  .refundPolicy {
    font-size: 1.2rem;
  }
  .flexbox_Empty_State {
    margin-top: 100px;
  }
  .savederrorjson {
    height: 240px;
    width: 240px;
  }
  .bold_text_Empty_State {
    font-size: 1.2rem;
  }
  .text2_Empty_State {
    margin-top: 10px;
    font-size: 1.2rem;
  }
  .btn_empty_state {
    margin-top: 40px;
    font-size: 1.3rem;
  }
  .customfilterfont {
    font-size: 1rem;
  }
  .toastbox {
    height: 3rem;
  }
  .toastfont {
    font-size: 1.3rem;
  }
  .toasticon {
    size: 2.4rem;
  }

  .toastclose {
    size: 2.1rem;
  }
  .customselectFont {
    font-size: 1.2rem;
    height: 3rem;
    padding: 15px;
  }
}

.alerts_border {
  border: 1px solid #e83f3f;
  box-shadow: 0px 1px 10px #e83f3f !important;
  animation: blink 0.6s;
  animation-iteration-count: 10;
}

@keyframes blink {
  30% {
    border-color: #fff;
    box-shadow: 0px 1px 20px #e83f3f !important;
  }
}

.super {
  font-size: medium;
  color: #e83f3f;
  margin-left: 2px;
}

.scrollbarhide::-webkit-scrollbar {
  display: none;
  width: 0;
}

.scrollbarhide {
  overflow-y: scroll;
  overflow-x: none;
}

@media screen and (max-width: 3000px) and (min-width: 800px) {
  .swal2-toast {
    height: 12%;
    align-content: center;
    justify-items: center;
    width: min-content !important;
  }

  .swal2-container {
    // width: 25% !important;
    min-width: 360px;
    width: fit-content !important;
  }
  .swal2-title {
    font-size: 1.2rem !important;
    white-space: nowrap;
  }
}

.footerglass {
  background: rgba(255, 255, 255, 0.8);
  // border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  // border: 1px solid rgba(255, 255, 255, 0.3);
}

.noscrollbar::-webkit-scrollbar {
  display: none;
  width: 0;
}
