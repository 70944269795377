@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;500;600;800;900&display=swap");


.hpSearchAnim {
  animation: hpSearchAnim-frames 1.00s ease 1.00s 1 normal forwards;
  transform: translate(-250px, 0);
  opacity: 0;
}


@keyframes hpSearchAnim-frames {
  from {
    opacity: 0;
    transform: translate(-250px, 0);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.hpHeaderData {
  animation: hpHeaderData 2.00s;

}

@keyframes hpHeaderData {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.hpCarImgWeb {
  animation: hpCarImgWeb-frames 1.00s ease 2.00s 1 normal forwards;
  opacity: 0;
  transform: translate(250px, 0);

}

@keyframes hpCarImgWeb-frames {
  from {
    opacity: 0;
    transform: translate(250px, 0);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.webhorizontalscroll::-webkit-scrollbar {
  display: none;
  width: 0px;

}

.webhorizontalscroll {
  /* overflow-y: scroll; */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

.hpSelect {
  background-color: transparent;
  /* height: 42px; */
  width: 100%;
  margin-top: 20px;
}

.hpSelect:first-of-type {
  margin-top: 0px;
}

.hpSelectError {
  position: absolute;
  color: red;
  font-size: 12px;
  margin-left: 5px;
}

.hpHeader {
  font-size: 2rem;
  font-family: "Manrope", sans-serif;
  font-weight: 700;

}

.hpHeaderdata {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  font-weight: 400;
  width: 83%;
  line-height: 2rem;
}

.hpHeaderdataextention1 {
  white-space: nowrap;
}

.hpqoutedata {
  font-size: 1rem;
  font-weight: 400;
}

.hpsubheaders {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
}

.hpsubheads {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
}

.hbProbc {
  background: #eaeaea;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.hbProbcImg {
  object-fit: contain;
  padding: 1vw;

}

.hbProbcTitles {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
}


.shopByBrandsScroll::-webkit-scrollbar {
  display: none;
  width: 0;
}

.shopByBrandsScroll {
  background-color: transparent;
  height: 200px;
  left: 55px;
  mix-blend-mode: normal;
  overflow-x: scroll;
  overflow-y: none;
  gap: 2.5rem;
  padding: 0.5rem;
}

.hpscroll-group-image {
  height: 70px;
  width: auto;

}

.highlightSec {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.highlightsImg {
  object-fit: contain;
  height: 75px;
}

.highlightsfont {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  margin-top: 10px;
  color: #959594;
}

.hpbtnAdDark {
  font-family: "Manrope", sans-serif !important;
  font-weight: 400 !important;
  font-size: 0.9rem !important;
  width: 110px !important;
  height: 2rem !important;
}

.hpbtnAdDarkE {
  font-family: "Manrope", sans-serif !important;
  font-weight: 400 !important;

}

.hpbtnAdDarkRound {
  font-family: "Manrope", sans-serif !important;
  font-weight: 400 !important;

}

.hpaAdLottie8b {
  height: 120px;
  width: 120px;
  right: 0;
  top: 0;
}

.btnSearchSignin {
  color: #0C0E2D;
  font-family: var(--font-family-nunito_sans);
  font-weight: 700 !important;
  white-space: nowrap;
  min-width: 150px;
  background: rgba(255, 255, 255, 0.2) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.01);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid #D0D0D0;

}

.hpTSGridItem {
  margin-right: 20px;
}

.hpTSGridItem:last-of-type {
  margin-right: 30px;
}


.hpTSBrandName,
.hpTSsellingPrice,
.hpTSProductTags {
  font-weight: 700;
}

.hpTSProductName,
.hpTScostPrice,
.hpTSdiscountPercentage,{
  font-weight: 400;
}


.hpTSProductName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 38vw;
  text-align: center;
  max-width: 250px;
}

.hpTScostPrice {
  color: #F04545;
  text-decoration: line-through;
}

.hpTSdiscountPercentage {
  color: #1ABC35;
}



@media screen and (max-width: 427px) {
  .hpHeader {
    font-size: 1.3rem;
    text-align: center;
  }

  .hpHeaderdata {
    width: 85%;
    font-size: 13px;
    line-height: 1.5rem;
    text-align: center;
    margin-top: 10px;
    line-height: 20px;
  }

  .hpSearchBox {
    margin-top: 20px;
  }


  .btnSearchSignin {
    font-size: 1rem !important;
  }

  /* .hpSelect {
    height: 50px;
    margin-top: 12px;
    box-shadow: 0px 0px 2px #00000033;
    width: 93%;

  } */

  .glassSearchbox {
    /* background: rgba(255, 255, 255, 0.8); */
    /* background: #fae10a78; */
    background: rgb(250 225 10 / 65%);
    /* border-radius: 16px; */
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .hpSelect {
    /* height: 30px; */
    margin-top: 3vh;
    /* box-shadow: 0px 0px 2px #00000033; */
    /* width: 93%; */
  }



  .hpSelect .customselectFont {
    /* height: 50px; */
    font-size: 14px;
    padding: 10px;
  }

  .hpSelect .FaChevronDownsize {
    font-size: 10px;
  }


  .hpbtnsearch {
    /* height: 2.3rem !important; */
    font-size: 0.9rem !important;
  }


  .hpsubheaders {
    font-size: 1rem !important;
  }

  .HPcategorygrid {
    margin: 4vw;
    margin-top: 10vw;
  }

  .hpsubheads {
    font-size: 1rem;
    text-align: center;
  }

  .hpsearchotherbtn {
    font-size: 0.9rem !important;
  }

  .hbProbcTitles {
    font-size: 0.8rem;
  }

  .hbProbc {
    border-radius: 15px;
  }

  .hbProbcImg {
    /* height: 150px; */
  }

  .shopByBrandsScroll {
    height: 70px;
    overflow-x: scroll;
    overflow-y: none;
    gap: 0.5rem;
    padding: 0.5rem;
  }

  .hpscroll-group-image {
    height: 50px;

  }

  .highlightsImg {
    height: 40px;
  }

  .highlightsfont {
    font-size: 9px;
  }

  .hpbtnAdDark {
    font-size: 0.7rem !important;
    width: 80px !important;
    height: 1.3rem !important;
  }

  .hpbtnAdDarkE {
    font-size: 0.7rem !important;
    width: 110px !important;
    height: 1.4rem !important;
  }

  .hpbtnAdDarkRound {
    font-size: 0.7rem !important;
    width: 50px !important;
    height: 2.2rem !important;
  }

  .hpQouteHeader {
    font-size: 0.8rem;
    font-family: "Manrope", sans-serif;
    font-weight: 600;
  }

  .hpqoutedata {
    font-size: 0.7rem;
    line-height: 15px;
    width: 90%;
  }

  .hpaAdLottie8b {
    height: 60px;
    width: 60px;
    right: 2vw;


  }

  .hpTopSellingImage {
    min-width: 150px;
    width: 30vw;
  }

  .hpTSBrandName,
  .hpTSProductName,
  .hpTScostPrice,
  .hpTSsellingPrice {
    font-size: 14px;
  }

  .hpTSdiscountPercentage,
  .hpTSProductTags {
    font-size: 12px;
  }

  .hpTSdatabox {
    min-height: 111px;
  }

}

@media screen and (max-width: 799px) and (min-width: 428px) {
  .hpHeader {
    font-size: 1.5rem;
    text-align: center;

  }

  .hpHeaderdata {
    width: 85%;
    font-size: 14px;
    line-height: 1.5rem;
    text-align: center;
    margin-top: 15px;
    line-height: 20px;
  }

  .hpSearchBox {
    margin-top: 30px;
  }


  .btnSearchSignin {
    font-size: 1.2rem !important;
  }

  .glassSearchbox {
    /* background: rgba(255, 255, 255, 0.8); */
    /* background: #fae10a78; */
    background: rgb(250 225 10 / 65%);
    /* border-radius: 16px; */
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .hpSelect {
    /* height: 55px; */
    margin-top: 3vh;
    /* box-shadow: 0px 0px 2px #00000033; */
    /* width: 93%; */

  }

  .hpSelect .customselectFont {
    /* height: 55px; */
    font-size: 16px;
    padding: 11px;
  }

  .hpSelect .FaChevronDownsize {
    font-size: 12px;
  }

  .hpbtnsearch {
    height: 2.8rem !important;
    font-size: 1rem !important;
  }

  .HPcategorygrid {
    margin: 4vw;
    margin-top: 10vw;
  }

  .hpsubheaders {
    font-size: 1.2rem;
  }

  .hpsearchotherbtn {
    font-size: 1rem !important;
  }

  .hpsubheads {
    font-size: 1.2rem;
    text-align: center;
  }

  .hbProbcTitles {
    font-size: 0.9rem;
  }

  .hbProbc {
    border-radius: 15px;
  }

  .hbProbcImg {}

  .shopByBrandsScroll {
    height: 75px;
    overflow-x: scroll;
    overflow-y: none;
    gap: 0.5rem;
    padding: 0.5rem;
  }

  .hpscroll-group-image {
    height: 60px;

  }

  .highlightsImg {
    height: 50px;
  }

  .highlightsfont {
    font-size: 12px;
  }

  .hpbtnAdDark {
    font-size: 0.9rem !important;
    width: 100px !important;
    height: 1.6rem !important;
  }

  .hpbtnAdDarkE {
    font-size: 1rem !important;
    width: 140px !important;
    height: 1.6rem !important;
  }

  .hpbtnAdDarkRound {
    font-size: 0.9rem !important;
    width: 56px !important;
    height: 2.7rem !important;
  }

  .hpQouteHeader {
    font-size: 1rem;
    font-family: "Manrope", sans-serif;
    font-weight: 600;
  }

  .hpqoutedata {
    font-size: 0.8rem;
    line-height: 20px;
    width: 90%;
  }

  .hpaAdLottie8b {
    height: 80px;
    width: 80px;
    right: 2vw;

  }

  .hpTopSellingImage {
    min-width: 200px;
    width: 30vw;
  }

  .hpTSBrandName,
  .hpTSProductName,
  .hpTScostPrice,
  .hpTSsellingPrice {
    font-size: 16px;
  }

  .hpTSdiscountPercentage,
  .hpTSProductTags {
    font-size: 14px;
  }

  .hpTSdatabox {
    min-height: 123px;
  }

}

@media screen and (max-width: 1023px) and (min-width: 800px) {

  .hpWebLandingSection {
    min-height: 500px;
  }

  .hpHeader {
    font-size: 1.2rem;
  }

  .hpHeaderdata {
    width: 110%;
    font-size: 0.8rem;
    line-height: 1.5rem;
  }

  .hpHeaderdataextention1 {
    display: none;
  }

  .hpSelect {
    height: 30px;
    margin-top: 18px;
  }

  .hpSelectError {
    font-size: 10px;
    margin-top: -1px;
  }


  .hpbtnsearch {
    height: 2.2rem !important;
    font-size: 0.8rem !important;
  }

  .hpsubheads {

    font-size: 1rem;
  }

  .hbProbcTitles {
    font-size: 0.5rem;
  }

  .hbProbcImg {
    height: 90px;
  }

  .highlightsImg {
    height: 40px;
  }

  .highlightsfont {
    font-size: 0.6rem;
  }

  .hpbtnAdDark {
    font-size: 0.7rem !important;
    width: 80px !important;
    height: 1.3rem !important;
  }

  .hpqoutedata {
    font-size: 0.8rem;
  }

  .hpaAdLottie8b {
    height: 60px;
    width: 60px;

  }
}

@media screen and (max-width: 1279px) and (min-width: 1024px) {

  .hpWebLandingSection {
    min-height: 600px;
  }

  .hpHeader {
    font-size: 1.8rem;
  }

  .hpHeaderdata {
    width: 94%;
  }

  .hpHeaderdataextention1 {
    display: none;
  }

  .hpSelect {
    height: 37px;
    margin-top: 12px;
  }

  .hpSelectError {
    font-size: 11px;
    margin-top: -1px;
  }

  .hpbtnsearch {
    height: 2.2rem !important;
    font-size: 0.8rem !important;
  }

  .hbProbcTitles {
    font-size: 0.7rem;
  }

  .hbProbcImg {
    height: 100px;
  }

  .highlightsImg {
    height: 50px;
  }

  .highlightsfont {
    font-size: 0.8rem;
  }

  .hpbtnAdDark {
    font-size: 0.8rem !important;
    width: 90px !important;
    height: 1.5rem !important;
  }

  .hpaAdLottie8b {
    height: 80px;
    width: 80px;

  }
}


@media screen and (max-width: 1365px) and (min-width: 1280px) {

  .hpWebLandingSection {
    min-height: 650px;
  }

  .hbProbcTitles {
    font-size: 0.9rem;
  }

  .highlightsImg {
    height: 65px;
  }

  .hpSelect {
    height: 42px;
    margin-top: 16px;
  }

  .hpaAdLottie8b {
    height: 100px;
    width: 100px;

  }

  .hpHeaderdata {
    width: 88%;
  }

  .hpHeaderdataextention1 {
    white-space: nowrap;
  }

}

@media screen and (max-width:1919px) and (min-width: 1366px) {

  .hpWebLandingSection {
    min-height: 780px;
  }

  .hpSelectError {
    font-size: 14px;
  }

  .hpbtnsearch {
    height: 3rem !important;
  }

}

@media screen and (min-width: 1920px) {

  .hpWebLandingSection {
    min-height: 800px;
  }

  .hpSelect {
    margin-top: 1.4rem;
  }

  .hpSelectError {
    font-size: 16px;
  }

  .hpbtnsearch {
    height: 3.2rem !important;
  }

  .hpHeader {
    font-size: 2.5rem;
    width: '90%'
  }

  .hpHeaderdata {
    font-size: 1.5rem;
    width: 88%;
    line-height: 3rem;
  }

  .hpHeaderdataextention1 {
    white-space: nowrap;
  }

  .hpsubheads {
    font-size: 2rem;

  }

  .hbProbcImg {
    height: 240px;
  }

  .hbProbcTitles {
    font-size: 1.4rem;

  }

  .hpbtnAdDark {
    font-size: 1.2rem !important;
    width: 150px !important;
    height: 2.8rem !important;
  }

  .shopByBrandsScroll {

    height: 320px;
  }

  .hpscroll-group-image {
    height: 120px;

  }

  .highlightsImg {
    height: 100px;

  }

  .highlightsfont {
    font-size: 1.3rem;
  }

  .hpqoutedata {
    font-size: 1.3rem;
  }

  .hpaAdLottie8b {
    height: 150px;
    width: 150px;

  }

}