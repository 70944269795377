@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,300,600|Manrope:400,700,500');

.screen a {
  display: contents;
  text-decoration: none;
}

.Lcontainer-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.Lcontainer-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

input:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}

.Llogin-singup {
  align-items: flex-start;
  /* background-color: #00000080; */
  display: flex;
  height: 768px;
  padding: 0 211px;
  width: 1366px;
}

.otp-login-container {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}

.otp-input-container {
  width: 330px;
  background-color: #fcfcfc;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.otp-input-container-phone {
  /* width: 330px; */
  z-index: 2;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.background-overlay {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 36%,
    rgba(255, 255, 255, 0.5524334733893557) 100%
  );
}

.background-overlay::before {
  background-image: url(../../../assets/sarath_IMG/login-background.svg);
  opacity: 0.3;
  background-size: cover;
  background-repeat: no-repeat;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.otp-title {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
}

.otp-title-phone {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 3rem;
}

.input-wrapper {
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
}

.input-wrapper input {
  width: 100%;
  padding: 10px;
  font-size: 0.9rem;
  /* box-shadow: 0px 1px 4px #00000026; */
  border: solid 1px #bdbdbd;
  border-radius: 5px;
}

.input-wrapper input::placeholder {
  font-size: 0.8rem;
}

.phone-input-error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.otp-timer-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  gap: 2px;
}

.send-otp-btn {
  position: absolute;
  right: 5px;
  background-color: #414bdb;
  color: white;
  top: 4px;
  border-radius: 5px;
  padding: 7px 7px;
  font-size: 0.8rem;
  font-weight: 600;
}

.edit-phone-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.resend-otp-btn {
  background-color: #414bdb;
  color: white;
  border-radius: 5px;
  padding: 3px 7px;
  font-weight: 500;
}

.resend-otp-btn:disabled {
  opacity: 0.5;
}

.request-otp-btn {
  position: absolute;
  background-color: var(--turbo);
  right: 7px;
  top: 4px;
  border-radius: 5px;
  padding: 3px 5px;
  font-size: 0.8rem;
  font-weight: 600;
}

.send-otp-btn:disabled,
.request-otp-btn:disabled {
  background-color: #d7d7d7;
  border-color: #626262;
}

.continue-btn-wrapper {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.continue-btn {
  padding: 7px 10px;
  border-radius: 5px;
  background-color: #414bdb;
  color: white;
  font-weight: 600;
  font-size: 0.8rem;
}

.continue-btn:disabled {
  background-color: #d7d7d7;
}

.otp-sent-message {
  text-align: center;
  font-weight: 500;
  font-size: 0.9rem;
}

.otp-input-container .disclaimer-text {
  text-align: center;
}

.Loverlap-group {
  -webkit-backdrop-filter: blur(50px) brightness(109%);

  backdrop-filter: blur(50px) brightness(109%);

  box-shadow: -10px 0px 45px #00000059;
  display: flex;
  height: 446px;

  margin-top: 17px;

  padding: 0 0px;
}

.Loverlap-group2 {
  align-items: center;
  background-color: var(--turbo);
  display: flex;
  flex-direction: column;
  margin-bottom: -0.4px;
  min-height: 446px;
  padding-top: 56px;
  padding-left: 20px;
  padding-right: 20px;
  width: 260px;
  justify-content: space-between;
}

.Loverlap-group8 {
  display: flex;
  justify-content: center;

  background-color: #ffffffbd;
  display: flex;
  flex-direction: column;
  margin-bottom: -0.4px;
  min-height: 446px;
  width: 330px;
}

.Lwelcome-back {
  align-self: center;
  color: #27292e;
  font-family: var(--font-family-manrope);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  margin-left: 0.16px;
  min-height: 25px;
  min-width: 118px;
  white-space: nowrap;
}

.Llorem-ipsum-dolor-si {
  color: #27292e;
  font-family: var(--font-family-manrope);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19px;
  margin-top: 56px;
  max-height: 100px;
  text-align: center;
}

.Llorem-ipsum-dolor-sir {
  color: #27292e;
  font-family: var(--font-family-manrope);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19px;
  margin-top: 5px;
  max-height: 100px;
  text-align: center;
}

.Lflex-col {
  align-items: center;
  display: flex;
  flex-direction: column;

  min-height: 370px;
}

.btn-css {
  font-size: 10px !important;
  height: 29px !important;
  margin-top: 5px;
  float: right;

  background-color: var(--royal-blue) !important;
  border-radius: 5px !important;
}

.Lsign-in-to-your-account {
  align-self: flex-start;
  color: #27292e;
  font-family: var(--font-family-manrope);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 0.18px;
  min-height: 21px;
  white-space: nowrap;
}

.Loverlap-group1 {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 1px 4px #00000026;
  height: 29px;
  margin-right: 0.18px;
  margin-top: 20px;
  position: relative;
  width: 216px;
}

.Lenter {
  background-color: transparent;
  border: 0;
  height: 16px;
  left: 6px;
  letter-spacing: 0;
  line-height: 16px;
  padding: 0;
  position: absolute;
  top: 6px;
  width: 180px;
  font-size: 14px !important;
}

.Lenter::placeholder {
  color: #acacac99;
}

.Lenter:-webkit-autofill,
.Lenter:-webkit-autofill:hover,
.Lenter:-webkit-autofill:focus,
.Lenter:-webkit-autofill,
.Lenter:-webkit-autofill:hover,
.Lenter:-webkit-autofill:focus,
.Lenter:-webkit-autofill,
.Lenter:-webkit-autofill:hover,
.Lenter:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(20, 19, 19);
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.Loverlap-group3 {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 1px 4px #0000001a;
  height: 29px;
  margin-right: 0.18px;
  margin-top: 2vh;
  position: relative;
  width: 216px;
}

.Lforgot {
  color: var(--royal-blue);
  cursor: pointer;
  font-family: var(--font-family-nunito_sans);
  font-size: 12px;
  font-weight: 400;
  left: 169px;
  letter-spacing: 0;
  line-height: 8px;
  position: absolute;
  top: 10px;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}

.Lforgot:hover,
.Lnew-to-brakeup-crea:hover {
  transform: scale(1.1);
}

.Lby-continuing-you-a {
  font-family: var(--font-family-manrope);
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.16px;
  line-height: 11px;
  margin-left: 1.82px;
  margin-top: 5px;
  min-height: 24px;
  width: 214px;
  text-align: center;
}

.Lgroup-31912 {
  align-items: flex-end;
  background-color: var(--royal-blue);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 29px;
  justify-content: flex-end;

  margin-top: 15px;
  min-width: 72px;
  padding: 4.3px 22px;
  transition: all 0.2s ease-in-out;
}

.Lgroup-31912:hover,
.Lgroup-31913:hover {
  transform: translate(0, -5px);
  box-shadow: 0px 12px 5px -8px rgba(0, 0, 0, 0.2);
}

.Llog-in {
  letter-spacing: 0;
  line-height: 9px;
  min-height: 14px;
  min-width: 27px;
  white-space: nowrap;
}

.Lline-18 {
  height: 1px;
  margin-left: 0.18px;
  margin-top: 16px;
  object-fit: cover;
  width: 216px;
}

.Lor {
  color: #626262;
  font-family: var(--font-family-manrope);
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 10px;

  margin-top: 8px;
  min-height: 15px;
  min-width: 11px;
  white-space: nowrap;
}

.Lgroup-31913 {
  align-items: flex-end;
  background-color: var(--royal-blue);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 29px;
  justify-content: flex-end;

  margin-top: 13px;
  min-width: 72px;
  padding: 4.3px 8px;
  transition: all 0.2s ease-in-out;
}

.Lrequest-otp {
  letter-spacing: 0;
  line-height: 9px;
  min-height: 14px;
  min-width: 55px;
  white-space: nowrap;
}

.Lnew-to-brakeup-crea {
  color: #27292e;
  cursor: pointer;
  font-family: var(--font-family-nunito_sans);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 9px;
  margin-right: 6.9px;
  margin-top: 62px;
  min-height: 14px;
  min-width: 171px;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  text-align: center;

  position: absolute;
  bottom: 14px;
}

.modal_position {
  top: 4rem;
  left: 46%;
}

@media screen and (max-width: 1200px) and (min-width: 1025px) {
  .modal_position {
    left: 38%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 801px) {
  .modal_position {
    left: 23%;
  }
}

@media screen and (max-width: 799px) {
  .modal_position {
    display: none !important;
  }
}

/* screen - login-sign-up-mobile-1 */

.login-sign-up-mobile-1 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100%;
}

.login-sign-up-mobile-1 .overlap-group_welcome {
  align-items: center;
  background-image: url(../../../assets/sarath_IMG/Signin.svg);
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  height: 100%;
  padding: 30px;
  width: 100%;
}

.login-sign-up-mobile-1 .overlap-group_forgot {
  align-items: center;
  background-image: url(../../../assets/sarath_IMG/ForgotPassword.svg);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;

  margin-top: 0;
  height: 100vh;
  padding: 30px;
  width: 100%;
}

.login-sign-up-mobile-1 .overlap-groupotp {
  align-items: center;
  background-image: url(../../../assets/sarath_IMG/OTPLogin.svg);
  /* background-position: 50% 50%; */
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;

  height: 100%;

  padding: 30px;
  width: 100%;
}

.login-sign-up-mobile-1 .overlap-create {
  align-items: center;
  background-image: url(../../../assets/sarath_IMG/Signin.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;

  margin-top: 0;
  height: 100%;
  padding: 30px;
  width: 100%;
}

.login-sign-up-mobile-1 .welcome-to-brakeup {
  color: var(--shark);
  font-family: var(--font-family-manrope);
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 19px;
  margin-top: 14vh;
  margin-bottom: 4vh;

  /* margin-top: 90px; */
  /* margin-bottom: 20px; */
  min-height: 28px;
  min-width: 189px;
  white-space: nowrap;
}

.login-sign-up-mobile-1 .overlap-group2 {
  align-items: center;
  background-color: var(--seashell);
  display: flex;
  height: 36px;
  justify-content: flex-end;

  /* margin-top: 18px; */
  margin-top: 2vh;
  width: 252px;
  padding: 6.2px 4.8px;
}

.login-sign-up-mobile-1 .inputmob {
  letter-spacing: 0.38px;
  height: 24px;
  min-width: 152px;
  white-space: nowrap;
  align-items: center;
  font-weight: 300;
}

.login-sign-up-mobile-1 .placeholder {
  font-size: 14px;
}

.placeholder:-webkit-autofill,
.placeholder:-webkit-autofill:hover,
.placeholder:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(20, 19, 19);
  -webkit-box-shadow: 0 0 0px 1000px #f1f1f1 inset;
}

.login-sign-up-mobile-1 .placeholder::placeholder {
  align-self: flex-end;
  color: var(--sonic-silver);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xxxl);
  font-weight: 300;
  letter-spacing: 0.3px;

  min-width: 152px;
  white-space: nowrap;
}

.login-sign-up-mobile-1 .disablled-tick {
  align-items: flex-end;
  background-image: url(../../../assets/sarath_IMG/path-79.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 13px;
  justify-content: flex-end;
  left: 233px;
  min-width: 13px;
  padding: 4.2px 3.1px;
  position: absolute;
  top: 11px;
}

.login-sign-up-mobile-1 .path-73946 {
  height: 5px;
  object-fit: cover;
  width: 7px;
}

.login-sign-up-mobile-1 .entered-state {
  display: flex;
  height: 18px;
  justify-content: space-between;
  left: 6px;
  position: absolute;
  top: 9px;
  width: 240px;
}

.login-sign-up-mobile-1 .anirudhanpravigmailcom {
  color: #2d2d2d;
  font-family: var(--font-family-nunito_sans);
  font-size: 13px;
  font-weight: 300;
  height: 20px;
  letter-spacing: 0;
  line-height: 13px;
  margin-top: 2.5px;
  white-space: nowrap;
  width: 158px;
}

.login-sign-up-mobile-1 .green-tick {
  height: 13.14px;
  margin-right: 0;
  margin-top: 2.4px;
  object-fit: cover;
  width: 13.14px;
}

.login-sign-up-mobile-1 .line-62 {
  height: 1px;
  opacity: 0.5;
  object-fit: cover;
  width: 252px;
}

.login-sign-up-mobile-1 .overlap-group3 {
  align-items: center;
  background-color: var(--seashell);
  display: flex;
  height: 36px;
  justify-content: flex-end;
  margin-top: 2vh;

  /* margin-top: 5px; */
  width: 252px;
  padding: 6.2px 4.8px;
}

.login-sign-up-mobile-1 .password {
  color: var(--sonic-silver);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xxxl);
  font-weight: 300;
  letter-spacing: 0.3px;

  min-width: 56px;
  white-space: nowrap;
}

.login-sign-up-mobile-1 .forgot {
  color: var(--royal-blue);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xxl);
  font-weight: 300;
  letter-spacing: 0;

  min-width: 37px;
  white-space: nowrap;
}

.login-sign-up-mobile-1 .line-63 {
  height: 1px;

  object-fit: cover;
  width: 252px;
}

.login-sign-up-mobile-1 .wrong-credentials {
  color: var(--orange-red);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xxl);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 11px;

  margin-top: 20px;
  min-height: 17px;
  min-width: 98px;
  white-space: nowrap;
}

.login-sign-up-mobile-1 .overlap-group {
  align-items: flex-end;
  background-color: var(--white);
  border: 0.3px solid var(--black);
  border-radius: 5px;
  min-height: 38px;
  /* margin-top: 20px; */
  margin-top: 4vh;
  padding: 7.3px 35.3px;
}

.login-sign-up-mobile-1 .overlap-group :active {
  background-color: rgb(206, 206, 206);
}

.login-sign-up-mobile-1 .login {
  align-items: flex-end;
  background-color: var(--white);
  border: 0.3px solid var(--black);
  border-radius: 5px;
  height: 38px;
  text-align: center;
  width: 115px;
  padding: 7.3px 35.3px;
  margin: 0px 2px;
}

.login-sign-up-mobile-1 .login23 {
  align-items: flex-end;
  background-color: var(--white);
  border: 0.3px solid var(--black);
  border-radius: 5px;
  height: 38px;
  text-align: center;
}
.login-sign-up-mobile-1 .log-in23 {
  color: var(--black);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xxxl);
  font-weight: 600;
  letter-spacing: 0;
  white-space: nowrap;
}

.login-sign-up-mobile-1 .log-in {
  color: var(--black);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xxxl);
  font-weight: 600;
  letter-spacing: 0;
  /* line-height: initial; */
  min-height: 18px;
  min-width: 42px;
  white-space: nowrap;
}

.login-sign-up-mobile-1 .line-61 {
  height: 1px;

  object-fit: cover;
  width: 216px;
}

.login-sign-up-mobile-1 .or {
  color: var(--silver-chalice);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxxxl);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 10px;
  margin-top: 3vh;

  min-height: 21px;
  min-width: 15px;
  white-space: nowrap;
}

.login-sign-up-mobile-1 .group-31902 {
  align-items: flex-end;
  border: 1px solid var(--turbo);
  border-radius: 5px;
  display: flex;
  height: 38px;

  margin-top: 1vh;
  min-width: 112px;
  padding: 8px 14px;
}

.login-sign-up-mobile-1 .group-31902:active {
  border: 1px solid var(--turbo);
  background-color: rgb(206, 206, 206);
  border-radius: 5px;
}

.login-sign-up-mobile-1 .request-otp {
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xxxl);
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  min-height: 18px;
  min-width: 84px;
  white-space: nowrap;
  margin-top: 2vh;
}

.new-to-brakeup-cre {
  color: var(--shark);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xxxl);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  /* margin-top: 50px; */
  margin-top: 4vh;
  margin-bottom: 6vh;
  min-height: 18px;
  min-width: 228px;
  white-space: nowrap;
}

.new-to-brakeup-crea {
  color: var(--shark);
  font-family: var(--font-family-nunito_sans);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
  margin-top: 10vh;
  margin-bottom: 6vh;
}

.new-to-brakeup-crea2 {
  color: var(--shark);
  font-family: var(--font-family-nunito_sans);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
  margin-top: 5vh;
  margin-bottom: 6vh;
}

.login-sign-up-mobile-1 .span1 {
  color: var(--royal-blue);
  font-weight: 600;
}

.login-sign-up-mobile-1 .by-continuing-you-a {
  color: var(--shark);
  font-family: var(--font-family-manrope);
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 18px;
  /* margin-bottom: -2px; */
  /* margin-top: 35px; */
  margin-top: 8vh;
  min-height: 16px;
  white-space: nowrap;
}

.login-sign-up-mobile-1 .span-1 {
  color: var(--silver-chalice);
}

/* screen - login-signup-iphone-12-pro-max */

.login-signup-iphone-12-pro-max {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 926px;
  overflow: hidden;
  width: 428px;
}

.login-signup-iphone-12-pro-max .overlap-group-5 {
  align-items: center;
  background-image: url(../../../assets/sarath_IMG/group3.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  margin-left: -26px;
  min-height: 944px;
  padding: 96.9px 69.3px;
  width: 502px;
}

.login-signup-iphone-12-pro-max .welcome-to-brakeup-1 {
  color: var(--shark);
  font-family: var(--font-family-manrope);
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 19.97px;
  margin-top: 148px;
  min-height: 35px;
  min-width: 239px;
  white-space: nowrap;
}

.login-signup-iphone-12-pro-max .overlap-group1-1 {
  align-items: center;
  align-self: flex-start;
  background-color: var(--seashell);
  display: flex;
  height: 49px;
  justify-content: flex-end;
  margin-left: 0;
  margin-top: 28px;
  min-width: 341px;
  padding: 11px 7.8px;
}

.login-signup-iphone-12-pro-max .inputmob {
  letter-spacing: 0.38px;
  min-height: 30px;
  min-width: 152px;
  white-space: nowrap;
  align-items: center;
  font-weight: 300;
}

.login-signup-iphone-12-pro-max .placeholder {
  font-size: 15px;
}

.login-signup-iphone-12-pro-max .placeholder::placeholder {
  align-self: flex-end;
  color: var(--sonic-silver);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xxxxxl);
  font-weight: 300;
  letter-spacing: 0.38px;

  min-height: 24px;
  min-width: 152px;
  white-space: nowrap;
}

.login-signup-iphone-12-pro-max .disabled-tick {
  align-items: flex-end;
  background-image: url(../../../assets/sarath_IMG/path-795.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 18px;
  justify-content: flex-end;
  margin-bottom: 1.36px;
  margin-left: 156px;
  min-width: 18px;
  padding: 5.7px 4.3px;
}

.login-signup-iphone-12-pro-max .path-73946-5 {
  height: 6px;
  object-fit: cover;
  width: 9px;
}

.login-signup-iphone-12-pro-max .line-71 {
  align-self: flex-start;
  height: 1px;
  object-fit: cover;
  width: 341px;
}

.login-signup-iphone-12-pro-max .overlap-group2-1 {
  align-items: flex-end;
  align-self: flex-start;
  background-color: var(--seashell);
  display: flex;
  height: 49px;
  margin-left: 0;
  margin-top: 20px;
  min-width: 341px;
  padding: 11px 7.8px;
}

.login-signup-iphone-12-pro-max .password-1 {
  color: var(--sonic-silver);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xxxxxl);
  font-weight: 300;
  letter-spacing: 0.38px;

  min-height: 24px;
  min-width: 70px;
  white-space: nowrap;
}

.login-signup-iphone-12-pro-max .forgot-5 {
  color: var(--royal-blue);
  font-family: var(--font-family-nunito_sans);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;

  min-height: 24px;
  min-width: 48px;
  white-space: nowrap;
}

.login-signup-iphone-12-pro-max .line-72 {
  align-self: flex-start;
  height: 1px;
  margin-top: 1px;
  object-fit: cover;
  width: 341px;
}

.login-signup-iphone-12-pro-max .wrong-credentials-5 {
  color: var(--orange-red);
  font-family: var(--font-family-nunito_sans);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 13px;
  margin-right: 5.97px;
  margin-top: 36px;
  min-height: 20px;
  min-width: 116px;
  white-space: nowrap;
}

.login-signup-iphone-12-pro-max .group-31983 {
  align-items: flex-end;
  background-color: var(--white);
  border: 0.3px solid var(--black);
  border-radius: 5px;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  margin-right: 8.97px;
  margin-top: 12px;
  min-width: 145px;
  padding: 5.8px 36.3px;
}

.login-signup-iphone-12-pro-max .log-in-5 {
  color: var(--black);
  font-family: var(--font-family-nunito_sans);
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 0;
  min-height: 30px;
  min-width: 71px;
  white-space: nowrap;
}

.login-signup-iphone-12-pro-max .line-70 {
  height: 1px;
  margin-right: 8.35px;
  margin-top: 31px;
  object-fit: cover;
  width: 293px;
}

.login-signup-iphone-12-pro-max .or-5 {
  color: var(--silver-chalice);
  font-family: var(--font-family-manrope);
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  margin-right: 5.97px;
  margin-top: 18px;
  min-height: 25px;
  min-width: 18px;
  white-space: nowrap;
}

.login-signup-iphone-12-pro-max .group-31984 {
  align-items: flex-end;
  border: 1px solid var(--turbo);
  border-radius: 5px;
  display: flex;
  height: 45px;
  margin-right: 7.97px;
  margin-top: 14px;
  min-width: 146px;
  padding: 7px 14px;
}

.login-signup-iphone-12-pro-max .request-otp-5 {
  color: var(--black);
  font-family: var(--font-family-nunito_sans);
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  min-height: 25px;
  min-width: 118px;
  white-space: nowrap;
}

.login-signup-iphone-12-pro-max .new-to-brakeup-crea-5 {
  color: var(--shark);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xxxxxl);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 15px;
  margin-left: 0.51px;
  margin-top: 49px;
  min-height: 22px;
  min-width: 285px;
  white-space: nowrap;
}

.login-signup-iphone-12-pro-max .span1-1 {
  color: var(--royal-blue);
  font-weight: 600;
}

.login-signup-iphone-12-pro-max .by-continuing-you-a-5 {
  color: var(--shark);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxl);
  font-weight: 400;
  letter-spacing: 0.22px;
  line-height: 20px;
  margin-bottom: -3px;
  margin-right: 19.97px;
  margin-top: 77px;
  min-height: 17px;
  min-width: 315px;
  white-space: nowrap;
}

.login-signup-iphone-12-pro-max .span-7 {
  color: var(--silver-chalice);
}

.errormsg {
  color: red;
  font-size: 12px;
}

.verifyicon {
  align-items: flex-end;
  color: gray;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;

  justify-content: flex-end;
  left: 190px;

  position: absolute;
  top: 5px;
}

.otpsentto {
  color: #626262;
  font-family: var(--font-family-manrope);
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;

  margin-top: 8px;
  min-height: 15px;
  min-width: 11px;
  white-space: nowrap;
}

.timer {
  position: absolute;
  left: 467px;
  font-size: 13px;
  font-family: var(--font-family-nunito_sans);
  color: #626262;
  top: 228px;
}

.timer-forgot {
  font-size: 13px;
  font-family: var(--font-family-nunito_sans);
  color: #626262;
}

.dot {
  margin-right: 4px;
  margin-left: 4px;
  height: 4px;
  width: 4px;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  display: inline-block;
}

.signuplogin {
  color: #27292e;
  cursor: pointer;
  font-family: var(--font-family-nunito_sans);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;

  width: 216px;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  text-align: center;

  position: absolute;
  bottom: 14px;
}

.signuploginhover:hover {
  transform: scale(1.1);
}

.mobresendotpp {
  position: absolute;
  top: 388px;
  left: 270px;
}

.loginotp_timer {
  /* position: absolute; */
  /* top: 569px;
  left: 266px; */
}

.resendbox {
  height: 38px;
  font-size: 14px;
  margin-top: 7px;
  min-width: 112px;
  padding: 8px 14px;
  text-align: center;
  line-height: initial;
}

/* new-user-log-in-start_purchase */

.new-user-log-in-mobile-1 {
  align-items: center;
  background-image: url(../../../assets/sarath_IMG/FinalPage.svg);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  /* height: 800px; */
  height: 100vh;
  overflow: hidden;
  padding: 20vh 0;
  width: inherit;
}

.new-user-log-in-mobile-1 .welcome9447309687 {
  color: var(--shark);
  font-family: var(--font-family-manrope);
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 0;

  /* margin-left: 2.0px; */
  /* min-height: 66px; */
  min-width: 138px;
  text-align: center;
  white-space: nowrap;
}

.new-user-log-in-mobile-1 .span0-1 {
  font-weight: 400;
}

.new-user-log-in-mobile-1 .group-31905 {
  align-items: center;
  background-color: var(--white);
  border: 2px solid #d1caca;
  border-radius: 5px;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-top: 4vh;
  min-width: 182px;
  padding: 12px 36.3px;
}

.new-user-log-in-mobile-1 .start-purchase-1 {
  color: var(--black);
  font-family: var(--font-family-nunito_sans);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;

  white-space: nowrap;
}

.new-user-log-in-mobile-1 .line-66 {
  height: 1px;

  margin-top: 5vh;
  object-fit: cover;
  width: 216px;
}

.new-user-log-in-mobile-1 .or-1 {
  color: var(--silver-chalice);
  font-family: var(--font-family-manrope);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 10px;

  margin-top: 2vh;
  min-height: 21px;
  min-width: 15px;
  white-space: nowrap;
}

.new-user-log-in-mobile-1 .overlap-group2 {
  align-items: center;
  background-color: var(--seashell);
  display: flex;
  height: 36px;
  justify-content: flex-end;

  margin-top: 2vh;
  width: 252px;
  padding: 6.2px 4.8px;
}

.new-user-log-in-mobile-1 .inputmob {
  letter-spacing: 0.38px;

  min-width: 152px;
  white-space: nowrap;
  align-items: center;
  font-weight: 300;
}

.new-user-log-in-mobile-1 .placeholder {
  font-size: 14px;
}

.new-user-log-in-mobile-1 .placeholder::placeholder {
  align-self: flex-end;
  color: var(--sonic-silver);
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xxxl);
  font-weight: 300;
  letter-spacing: 0.3px;

  min-width: 152px;
  white-space: nowrap;
}

.new-user-log-in-mobile-1 .line-62 {
  height: 1px;
  opacity: 0.5;
  object-fit: cover;
  width: 252px;
}

.new-user-log-in-mobile-1 .group-31902 {
  align-items: center;
  border: 1px solid var(--turbo);
  border-radius: 5px;
  display: flex;
  height: 38px;

  margin-top: 4vh;
  min-width: 112px;
  padding: 8px 14px;
}

.new-user-log-in-mobile-1 .group-31902:active {
  border: 1px solid var(--turbo);
  background-color: rgb(206, 206, 206);
  border-radius: 5px;
}

.new-user-log-in-mobile-1 .request-otp {
  font-family: var(--font-family-nunito_sans);
  font-size: var(--font-size-xxxl);
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  min-height: 18px;
  min-width: 84px;
  white-space: nowrap;
}
/* 
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {

  -webkit-text-fill-color: rgb(20, 19, 19);

  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
} */

.login-sign-up-mobile-1 .resetpassword_button {
  align-items: flex-end;
  background-color: var(--white);
  border: 0.3px solid var(--black);
  border-radius: 5px;
  height: 38px;
  text-align: center;
  /* margin-top: 20px; */
  /* width: 115px; */
  padding: 7.3px 15.3px;
}

.login-sign-up-mobile-1 .resetpassword_button :active {
  border: 1px solid var(--turbo);
  background-color: rgb(206, 206, 206);
  border-radius: 5px;
}
