/* layout */

.root {
    background: white !important;
}


/* savedlist */


.mob_cart-items {

    background-color: var(--white);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 0px 34px 40px;
    width: 100%;
    min-height: 15vw;
}


.mob_cart-items_1s {

    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000026;
    margin-top: 4px;
    height: max-content;
    width: auto;
    padding: 12.4px 14px;
    justify-content: space-between;
}

.mob_overlap-1s {
    align-items: center;
    display: flex;
    height: 100%;
    left: 0;
    position: relative;
    width: 100%;
    top: 0;
}



.mob_text-7-1 {
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 1.62px;
    min-height: 21px;
    min-width: 14px;
    white-space: nowrap;
    font-size: 1.1rem;

}

.mob_brake-pad-12s {
    height: 100px;

    margin-left: 10px;
    object-fit: cover;
    width: auto;
}

.mob_flex-col-21s {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    margin-left: 3vw;
    /* min-height: 180px; */
    width: 100%;
}

.mob_brembo-22 {
    text-transform: uppercase;
    letter-spacing: 0;
    min-width: 140px;
    white-space: nowrap;
    font-size: 1.25rem;

}

.mob_front-brake-pads-7 {
    letter-spacing: 0;

    min-height: 26px;
    min-width: 140px;
    white-space: nowrap;
    font-size: 1.25rem;
}

.mob_in-stock-9 {
    letter-spacing: 0;

    min-height: 24px;
    white-space: nowrap;
    min-width: 4vw;
    font-size: 1.1rem;
}

.mob_out_of_stock {
    letter-spacing: 0;

    min-height: 24px;
    white-space: nowrap;
    min-width: 5vw;
    font-size: 1.1rem;
}

.mob_delivery-by-12s {
    align-items: baseline;
    min-width: 13vw;
    margin-top: 5px;
}

.mob_delivery-by-13 {

    letter-spacing: 0;

    white-space: nowrap;
    /* font-size: 1.1rem; */
}

.mob_x15th-september-8s {

    letter-spacing: 0;
    font-weight: 600;
    margin-left: 4px;
    white-space: nowrap;
    font-size: 1.1rem;
}

.mob_dispatch-from-7s {}

.mob_dispatch-from-8 {

    letter-spacing: 0;

    white-space: nowrap;

}

.mob_place-30 {

    letter-spacing: 0;
    text-transform: capitalize;
    margin-left: 2px;
    white-space: nowrap;

}

.mob_unit-price-6 {
    margin-top: 1.5vw;
    /* font-size: 1.1rem; */
}

.mob_unit-price-7 {

    letter-spacing: 0;

    white-space: nowrap;

}

.mob_rs-144900-3 {

    letter-spacing: 0;

    margin-left: 10px;
    white-space: nowrap;

}

.mob_quantity-14 {


    margin-top: 14px;
    align-items: end;
}

.mob_quantity-15 {

    letter-spacing: 0;
    align-items: flex-end;
    white-space: nowrap;
    font-size: 1.25rem;
}

.mob_overlap-group-61 {
    align-items: center;
    background-color: var(--white);
    /* box-shadow: 0px 0px 2px #00000040; */
    display: flex;

    justify-content: flex-end;
    margin-left: 6px;

    padding: 0 5.8px;
}

.mob_add_to_cart {
    height: auto;
    width: 82px;
    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0px 0px 2px #00000040;
    /* font-size: 1.1rem !important; */
}



.mob_flex-col-22s {
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 2.14px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.mob_closebtns {
    position: absolute;
    top: 0;
    right: 0;
}



.mob_product-1-price-6 {

    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-right: 10px;

}

.mob_x30-off-15 {
    margin-top: 2px;
    letter-spacing: 0;

    text-align: right;

    white-space: nowrap;
    font-size: 1.1rem;

}

.mob_text-7-2 {
    letter-spacing: 0;

    text-align: right;
    text-decoration: line-through;
    white-space: nowrap;
    font-size: 1.1rem;
}


.mob_rs-191800-11 {
    letter-spacing: 0;
    text-align: right;
    white-space: nowrap;
    font-size: 1.25rem;
}

.mob_move-to-cart-N1E04U {
    background-color: transparent;
    height: 15vw;
    max-height: 215px;
    left: calc(47.00%);
    position: absolute;
    top: 30%;
    width: 35vw;
    max-width: 500px;
    box-shadow: 0px 0px 10px #00000059;
    background-color: var(--white-rock-2);
    border-radius: 5px;
}




.mob_item-moved-to-cart-qOY6ju {
    background-color: transparent;
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;

    left: 13vw;
    letter-spacing: 0.00px;
    line-height: 18px;
    position: absolute;
    text-align: left;
    top: 20%;
    white-space: nowrap;
    width: 100%;
}


.mob_okaycancel {
    background-color: transparent;
    position: absolute;
    right: 10%;
    bottom: calc(27.64% - 8px);
    height: 2.5vw;
}



.mob_rectangle-5731-MGcnFb {
    background-color: var(--white);
    border-radius: 2px;
    box-shadow: 0px 3px 6px #00000029;
    width: 4vw;
    margin-right: 15px;

}

.mob_close-MGcnFb {
    background-color: transparent;
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    left: 6px;
    letter-spacing: 0.00px;

    text-align: center;
    white-space: nowrap;
}


.mob_rectangle-5732-cbIu4D {
    background-color: var(--royal-blue);
    border-radius: 2px;
    box-shadow: 0px 3px 6px #330d2f29;
    width: 5vw;
}


.mob_view-cart-cbIu4D {
    background-color: transparent;
    color: var(--white);
    font-family: var(--font-family-nunito_sans);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;

    letter-spacing: 0.00px;

    text-align: center;

    white-space: nowrap;

}

.mob_group-32692-qOY6ju {
    background-color: transparent;
    height: 13px;
    position: absolute;
    right: 13px;
    top: 4px;

}


.mob_rectangle-5762-qOY6ju {
    background-color: transparent;
    height: 7vw;
    left: 7%;
    overflow: hidden;
    position: absolute;
    top: calc(36.92% - 32px);
    width: auto;
}


.mob_are-you-sure {
    background-color: transparent;
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;

    left: 2vw;
    letter-spacing: 0.00px;
    line-height: 18px;
    position: absolute;
    text-align: left;
    top: 20%;
    white-space: nowrap;
    width: 100%;
}

.mob_alertfont {
    font-size: 0.9rem !important;
}

/* mobile */

.mob_detailbox {
    white-space: nowrap;
    box-shadow: 0px 0px 2px #00000029;
    padding: 3px;

}

.mob_logo-logo-png {
    height: 25px;
    width: auto;
    min-width: 25px;


}

.mob_x530ds {
    margin-left: 4px;
}

.mob_f30s {
    margin-left: 4px;
}

.mob_x2015s {
    margin-left: 4px;
}

.mob_diesels {
    margin-left: 4px;
}

.mob_group-32001 {
    background-color: transparent;
    height: 100%;
    right: 0px;
    top: 0px;
    width: 57px;
}


.mob_rectangle-5244 {
    background-color: var(--white);
    box-shadow: 0px 0px 1px #00000026;
    height: 20px !important;
    border-radius: 1px !important;
    border: none;
    padding: 0px !important;
    text-align: center !important;
    width: 19px !important;
    min-width: 19px !important;
    margin-inline-start: 0px !important;
    -webkit-margin-start: 0px !important;
}


.mob_rectangle-5404 {
    background-color: var(--cultured-pearl);
    height: 20px !important;
    border-radius: 1px !important;
    padding: 0px !important;
    width: 19px !important;
    min-width: 19px !important;
}

.mob_rectangle-5403 {
    background-color: var(--cultured-pearl);
    height: 20px !important;
    border-radius: 1px !important;
    padding: 0px !important;
    width: 19px !important;
    min-width: 19px !important;
    margin-inline-start: 0px !important;
    -webkit-margin-start: 0px !important;
}




@media screen and (max-width: 799px) {


    .mob_text-7-1 {

        font-size: 0.6rem !important;
    }

    .mob_brembo-22 {
        font-size: 1rem !important;
    }

    .mob_front-brake-pads-7 {

        font-size: 1rem;
    }

    .mob_detailbox {
        font-size: 0.8rem;
    }


    .mob_in-stock-9 {

        font-size: 0.8rem;
        min-width: max-content;
    }

    .mob_out_of_stock {
        font-size: 0.55rem;
    }


    .mob_delivery-by-13 {
        font-size: 0.8rem;
    }

    .mob_x15th-september-8s {
        font-size: 0.7rem;
    }

    .mob_dispatch-from-7s {


        font-size: 0.8rem;
    }

    .mob_unit-price-6 {

        font-size: 0.55rem;
    }

    .mob_quantity-15 {
        font-size: 0.8rem;
    }

    .mob_add_to_cart {
        font-size: 0.7rem !important;
    }

    .mob_x30-off-15 {
        font-size: 0.6rem;

    }

    .mob_text-7-2 {

        font-size: 0.6rem;
    }

    .mob_rs-191800-11 {
        font-size: 0.9rem;
    }

    .mob_item-moved-to-cart-qOY6ju {
        font-size: 1rem;

    }

    .mob_close-MGcnFb {
        font-size: 0.6rem;
    }

    .mob_view-cart-cbIu4D {
        font-size: 0.6rem;
    }

    .mob_are-you-sure {
        font-size: 1rem;
    }

}

/* Gift&Coupons */

.mob_rectangle-json {
    background-color: transparent;
    max-height: 400px;
    height: 20vw;
    left: calc(49.93% - 211px);
    overflow: hidden;

    top: 0px;
    width: auto;
}

.mob_we-do-not-have-gifting-options-right-now-gX6Hov {
    background-color: transparent;

    margin-top: 5vw;
    margin-bottom: 5vw;
    letter-spacing: 0.00px;
    font-size: 1.2rem;

    text-align: center;
    white-space: nowrap;

}

.mob_if-you-have-a-promoc-YLfhehs {
    background-color: transparent;
    color: var(--mountain-mist);
    font-family: var(--font-family-nunito_sans);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;

    letter-spacing: 0.00px;

    right: 0;
    text-align: center;
    margin-top: 20px;
    white-space: nowrap;

}



@media screen and (max-width: 799px) {


    .mob_we-do-not-have-gifting-options-right-now-gX6Hov {
        font-size: 1.1rem;
    }

    .mob_if-you-have-a-promoc-YLfhehs {
        font-size: 1rem;
    }

    .mob_rectangle-json {
        height: 322px;
        margin-top: 20px;
    }

}

/* my garage */

.mob_rectangle-6045-qYBBoK {
    background-color: transparent;
    height: calc(100.00% - 77px);
    left: calc(48.82% - 156px);
    overflow: hidden;

    top: 19px;
    width: 320px;
}

.mob_my-garage-will-be-rolled-out-soon-qYBBoK {
    background-color: transparent;

    height: 26px;
    letter-spacing: 0.00px;
    font-size: 1.2rem;
    text-align: center;
    white-space: nowrap;
    width: 100%;
}



@media screen and (max-width: 800px) and (min-width: 200px) {


    .mob_my-garage-will-be-rolled-out-soon-qYBBoK {
        font-size: 1rem;
    }

    .mob_rectangle-6045-qYBBoK {
        background-color: transparent;
        width: 320px;
        height: 400px;
    }

}

/* address */



.mob_lottie_address {
    background-color: transparent;
    height: 4vw;
    width: 4vw;

}

.mob_lottie_nameM {
    background-color: var(--sizzling-sunrise);
    border-radius: 10px;
    padding: 3px;
    width: 4vw;
    min-width: 40px;
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
}

.mob_home-address {
    height: max-content;
    font-size: 1rem;
}

.mob_add_addressM {
    background-color: var(--turbo) !important;
    border-radius: 5px !important;
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: center;
    white-space: nowrap;
}


.mob_addressbox {
    background-color: var(--white-rock-2);
    border-radius: 5px;
    box-shadow: 0px 0px 10px #00000059;
    width: 40vw !important;
    min-width: 500px;
}

.mob_add_edit_adress {

    color: var(--black-pearl);
    font-family: var(--font-family-manrope);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 1.5vw;
}

.mob_lottie_address_modal {
    background-color: transparent;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: -3vw;

    width: 20vw;
    min-width: 240px;
    max-width: 300px;
}


.mob_inputbox {
    background-color: var(--white);
    border: none;
    border-radius: 2px;
    box-shadow: 0px 1px 4px #00000033;
    height: 2rem;
    margin-top: 1rem !important;
    padding-left: 10px;
    padding-right: 5px;
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    width: 100%;

}

.mob_inputbox3 {
    background-color: var(--white);
    border: none;
    border-radius: 2px;
    box-shadow: 0px 1px 4px #00000033;
    height: 2rem;
    /* margin-top: 1rem ; */
    padding-left: 10px;
    padding-right: 5px;
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    width: 100%;

}

.mob_place_element {

    color: #ACACAC;
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 400;
}

.mob_inputbox2 {
    background-color: var(--white) !important;
    border: none !important;
    border-radius: 2px !important;
    box-shadow: 0px 1px 4px #00000033;
    height: 2rem !important;
    margin-top: 1rem;
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 400;
    min-width: 165px !important;


}

.mob_cancel {
    background-color: transparent !important;
    border-radius: 5px !important;
    width: 5vw;
    max-width: 85px;
    min-width: 50px;
    letter-spacing: 0.00px;
    text-align: center;
    white-space: nowrap;
    font-size: 1rem !important;
    border: 1px solid var(--melanzane);
}

.mob_radio_buttons {


    border-color: #f9e107;
    height: 1.5rem !important;
    width: 1.5rem !important;
}

.css-10j71as[aria-checked=true], .css-10j71as[data-checked] {

    background: var(--turbo) !important;
    border-color: var(--turbo) !important;
    color: var(--chakra-colors-white);
}

.css-1q7jgol[aria-checked=true], .css-1q7jgol[data-checked] {
    background: var(--turbo) !important;
    border-color: var(--turbo) !important;
    color: var(--chakra-colors-white);
}

.css-1q7jgol[aria-checked=true], .css-1or39uu[data-checked] {
    background: var(--turbo) !important;
    border-color: var(--turbo) !important;
    color: var(--chakra-colors-white);

}

.radiobox {
    font-size: 11px;
}

.mob_save_proceed {
    background-color: var(--royal-blue) !important;
    color: var(--white);
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    border-radius: 5px !important;

    min-width: 165px;
    max-width: 220px;
    width: 17vw;
}

.mob_save_proceed_mob {
    font-family: var(--font-family-nunito_sans);
    font-size: 0.8rem !important;
    font-style: normal;
    font-weight: 400;

    width: 200px !important;
}

@media screen and (max-width: 427px) {

    .mob_lottie_address {
        width: 45px;
        height: 45px;
    }

    .mob_lottie_nameM {
        font-size: 7px;
        border-radius: 7px;
        padding: 1px;
    }

    .mob_home-address {
        font-size: 0.8rem;
    }

    .mob_add_address {
        font-size: 0.8rem !important;
    }

    .mob_inputbox {
        font-size: 0.8rem !important;
    }

    .mob_place_element {
        font-size: 0.8rem !important;
    }

    .mob_inputbox3 {
        font-size: 0.8rem !important;

    }

    .mob_inputbox2 {
        font-size: 0.8rem !important;

    }

    .mob_radiobox {
        font-size: 9px;
    }



}


@media screen and (max-width: 799px) and (min-width: 428px) {

    .mob_lottie_address {
        width: 45px;
        height: 45px;
    }

    .mob_lottie_nameM {
        font-size: 8px;
        border-radius: 7px;
        padding: 1px;
    }

    .mob_home-address {
        font-size: 0.8rem;
    }

    .mob_add_address {
        font-size: 0.8rem !important;
    }

    .mob_inputbox {
        font-size: 0.8rem !important;
    }

    .mob_place_element {
        font-size: 0.8rem !important;
    }

    .mob_inputbox3 {
        font-size: 0.8rem !important;

    }

    .mob_inputbox2 {
        font-size: 0.8rem !important;

    }

    .mob_radiobox {
        font-size: 9px;
    }




}





/* notifications */

.mob_notifications {

    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000026;
    margin-top: 40px;
    max-height: max-content;
    min-height: 140px;
    width: auto;
    padding: 12.4px 14px;

}

.mob_place-lottie-here-o8tpQI {
    background-color: transparent;
    height: auto;

    overflow: hidden;

    width: 3vw;

}

.mob_contrary-to-popular-o8tpQI {

    background-color: transparent;
    letter-spacing: 0.00px;
    text-align: left;
    width: 80%;
    margin-right: 10%;
    font-size: 1rem;
}

.mob_markCross {
    justify-content: end;

    margin-right: 5px;
}

.mob_mark-as-read-o8tpQI {

    background-color: transparent;

    letter-spacing: 0.00px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    font-size: 0.8rem;

}

.mob_delete-this-notification {
    background-color: transparent;

    letter-spacing: 0.00px;
    line-height: 18px;
    position: absolute;
    text-align: right;
    top: 25%;
    white-space: nowrap;
    width: 30vw;
    max-width: 450px;
    font-size: 1rem;

}

.mob_item-moved-to-cart-qOY6ju {
    background-color: transparent;
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;

    left: 13vw;
    letter-spacing: 0.00px;
    line-height: 18px;
    position: absolute;
    text-align: left;
    top: 20%;
    white-space: nowrap;
    width: 100%;
}


.mob_canceldelete {
    background-color: transparent;
    position: absolute;
    right: 10%;
    bottom: calc(27.64% - 8px);
    width: 12vw;
    max-width: 170px;
    min-width: 105px;
    max-height: 30px;
    height: 2vw;
    justify-content: space-between;
    font-size: 0.7rem;
}

.mob_rectangle-5291-6C7pzf {
    background-color: transparent;
    border-radius: 5px;
    height: 100%;
    width: 5vw;
    max-width: 70px;
    min-width: 50px;

}

.mob_cancel-6C7pzf {
    background-color: transparent;
    letter-spacing: 0.00px;
    text-align: center;
    white-space: nowrap;
    font-size: 0.7rem;
}

.mob_rectangle-5290-xcKw32 {
    background-color: var(--royal-blue);
    border-radius: 5px;
    width: 5vw;
    max-width: 70px;
    min-width: 50px;
}

.mob_delete-xcKw32 {
    background-color: transparent;
    letter-spacing: 0.00px;
    text-align: center;

    white-space: nowrap;

}

.mob_lottiedelete {

    background-color: transparent;
    height: 8vw;
    max-height: 115px;
    left: 10%;
    overflow: hidden;
    position: absolute;
    top: 22%;
    width: auto;
}

@media screen and (max-width: 799px) {


    .mob_contrary-to-popular-o8tpQI {
        font-size: 0.8rem;
    }

    .mob_markCross {
        height: 20px;
        align-items: center;
        margin-right: 20px;
    }

    .mob_mark-as-read-o8tpQI {

        font-size: 0.7rem;
    }

    .mob_place-lottie-here-o8tpQI {
        width: 25px;
    }

    .mob_delete-this-notification {
        font-size: 0.8rem;

    }

    .mob_cancel-6C7pzf {

        font-size: 0.6rem;

    }

    .mob_delete-xcKw32 {

        font-size: 0.6rem;

    }
}

/* order history */

@media screen and (max-width: 799px) {

    .mob_order_header {
        padding: 20px;
    }

    .mob_order_headings {
        font-size: 11px;
        margin-top: 10px;
    }

    .mob_headerdisplayweb {
        display: none !important;
    }

    .mob_headerdisplaytab {
        display: flex !important;
    }


    .mob_order_head_sub {
        font-size: 11px;
        margin-left: 20px;
        margin-top: 10px;
    }

    .mob_order_head_sub_time {
        font-size: 9px;
    }

    .mob_orderdata {
        padding: 30px 10px 50px;
        width: 100%;

    }

    .mob_order_image_qua {
        min-width: 80px;
    }

    .mob_order_image {
        width: 80px;
        cursor: pointer;
    }

    .mob_order_brand {
        font-size: 14px;
    }

    .mob_order_product {
        font-size: 14px;
    }
    .mobredirect{
        height: 79px;
        cursor: pointer;
    }

    .mob_order_product_data {
        font-size: 13px;
    }

    .mob_dispatchorder {
        margin-top: 10px;
    }

    .mob_order_quantity_dis {
        font-size: 12px;
    }

    .mob_order_dotted_text {
        padding: 6px;
        font-size: 10px;
    }

    .mob_order_button_big {
        padding: 5px 15px !important;
        height: 1.7rem !important;
        font-size: 11px !important;
        font-weight: 400 !important;
    }

    .mob_order_button_med {

        padding: 10px 20px !important;
        height: 1.7rem !important;
        font-size: 11px !important;
        font-weight: 400 !important;
        margin-left: 10px;

    }

    .mob_order_button_sml {

        height: 1.7rem !important;
        font-size: 11px !important;
        font-weight: 400 !important;

    }

    .mob_order_bot_btn {
        margin-right: 10vw;
        margin-top: 0;
    }

    .mob_order_button_track {

        height: 1.5rem !important;
        font-size: 10px !important;
        font-weight: 400 !important;

    }

}






@media screen and (max-width: 799px) {


    /* PROFILE */



    .mob_inputoutput {
        font-size: 1rem;
    }

    .mob_inputoutput_text {
        margin-top: 40px;
        height: 24px;
    }

    .mob_inputbox_profile {
        background-color: var(--white);
        border: none;
        border-radius: 2px;
        box-shadow: 0px 1px 4px #00000033;
        height: 2rem;
        padding-left: 10px;
        padding-right: 5px;
        font-family: var(--font-family-nunito_sans);
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 400;
        width: 100%;
        margin-top: 12px;

    }



    /* change password */


    .mob_pass_heading {
        font-size: 1rem;
    }

    .mob_inputbox_change_pass_profile {
        background-color: var(--white);
        border: none;
        border-radius: 2px;
        box-shadow: 0px 1px 4px #00000033;
        height: 2rem;
        padding-left: 10px;
        padding-right: 5px;
        font-family: var(--font-family-nunito_sans);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        width: 100%;

    }

    .mob_passfont {
        font-size: 1rem;
    }

    .mob_confirm_btns {
        font-size: 0.9rem !important;
    }

    /* PASSWORD CHANGED  */
    /* PASSWORD ERROR */

    .mob_password_lotties {
        height: 75px;
        width: 75px;
        margin-bottom: 40px;
    }


    .mob_password_error_lotties {
        height: 130px;
        width: 200px;

    }

    /* DEACTIVATE 1 */



    .mob_confirm_btn1s {
        font-size: 0.8rem !important;
        font-weight: 800 !important;
        height: 2rem !important;
    }

    .mob_deactivate1_lotties {
        height: 150px;
        width: 120px;
        margin-top: 40px;
        margin-bottom: 55px;
    }

    /* DEACTIVATE 2 */

    .mob_deactivate2_lotties {
        height: 200px;
        width: 200px;
        margin-bottom: 1px;
    }

    .mob_deactivate_heading2s {
        font-size: 0.8rem;
        white-space: nowrap;
        text-transform: uppercase;

    }

    .mob_resend_deactivates {
        font-size: 0.9rem;
    }

    .mob_timer_deactivates {
        font-size: 0.8rem;
        margin-left: 85px;
        margin-top: -20px;
    }

    /* DEACTIVATE FINAL */

    .mob_deactivate3_lottie {
        width: 250px;

    }

    .mob_deactivate3_text_sections {
        margin-top: 170px;
    }

    .mob_it_takess {
        font-size: 1.2rem;

    }

    .mob_we_cans {
        font-size: 0.7rem;
    }

    .mob_your_accnts {
        font-size: 0.8rem;
    }

    .mob_profile {

        padding: 40px 40px 40px;

    }

    .mob_main_box_profiles {
        padding: 30px 10px 30px 30px;
        width: 90%;
    }

    .mob_heading_profile {
        font-size: 1rem;
    }

    .mob_review_heading_profile {
        font-size: 1rem;
    }

    .mob_inputoutput_text {
        font-size: 0.9rem;
    }

    .mob_inputoutput_text {
        margin-top: 20px;
    }

    .mob_inputbox_profile {
        margin-top: 12px;

    }

    .mob_button_edit_saves {
        font-size: 0.8rem !important;
        height: 1.8rem !important;
    }

    .mob_btn3 {
        font-size: 0.8rem !important;
        height: 1.8rem !important;
    }

    .mob_feature_rolled_out {
        font-size: 0.8rem;
        margin-top: 20px;
    }

    .mob_review_box {
        margin-top: 10%;
        margin-bottom: 15%;
    }

    .mob_password_headng {
        font-size: 0.8rem;
    }

    .mob_deactivate_heading {
        font-size: 0.9rem;

    }

}