.vehicleList {
  background: #ececec 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1019607843);
  border-radius: 4px;
  opacity: 1;
  text-align: left;
  font-size: 18px;
  font-weight: lighter;
  letter-spacing: 0px;
  color: #27292e;
  text-transform: uppercase;
}

.dropdowns {
  font-size: 25px;
}

.css-mt2q39 {
  height: 4vh !important;
  min-height: 4vh !important;
  text-transform: capitalize;
  background-color: #ffffff !important;
}

.css-fdy22n {
  background-color: #ffffff !important;
}

.css-1bykix3 {
  height: 0% !important;
}

.productList {
  background: #ececec 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1019607843);
  border-radius: 4px;
  opacity: 1;
  text-align: left;
  font-size: 18px;
  font-weight: lighter;
  letter-spacing: 0px;
  color: #27292e;
  text-transform: uppercase;
}/*# sourceMappingURL=filterbox.css.map */